.session-container{
    width: 100%;
    margin: 15px 0px;
}
.se-list{
    border-bottom: 1px solid #eceff1;
    border-top: 1px solid #eceff1;
    padding: 10px 0px;
    width: 100%;
    margin-top: -2px;
    justify-content: space-between;
    background: white;
}
.se-name{
    display: flex;
    cursor: pointer;
    align-items: center;
}
.se-date{
    display: flex;
    padding-right: 15px;
    font-size: 12px;
}
.session-onExpand{
    padding: 0px 23px;
}
.session-modal-container{
    width: 80%;
}
.downloadLink{
    background: #80808014;
    padding: 2px 10px;
    border-radius: 2px;
    margin-right: 10px;
}