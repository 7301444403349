.fullpage-spinner {
    padding: 30px;
    height:20%;
    width: 100%;
    justify-content: center;
    position: absolute;
    left: 0;
    background: #0000002b;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAAyCAYAAADm33NGAAAABHNCSVQICAgIfAhkiAAAAFZJREFUaIHtzwENACAMwLCDG/wbxAUkzapgWzNzBrd/B7zQpKJJRZOKJhVNKppUNKloUtGkoklFk4omFU0qmlQ0qWhS0aSiSUWTiiYVTSqaVDSpaFJxARMkAI2z1aBqAAAAAElFTkSuQmCC");
    z-index: 999999999;
}

.loading-spinner-container {
    text-align: center;
    width: auto;
    padding: 30px;
    margin: 0 auto;
    margin-top: 113px;
    background-color: #fff;
    box-shadow: 0 0 2px #c3c3c3;
    display: inline-table;
}

.spinner-border {
    /* display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: .75s linear infinite spinner-border; */
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
}




  .spinner-border::before , .spinner-border::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #FFF;
    animation: prixClipFix 2s linear infinite ;
  }
  .spinner-border::after{
    border-color: #FF3D00;
    animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;
    inset: 6px;
  }
  .loader-button{
    height: 20px;
    width: 20px;
  }
  .loader-button::before , .loader-button::after{
    border: none;
  }

  @keyframes rotate {
    0%   {transform: rotate(0deg)}
    100%   {transform: rotate(360deg)}
  }

  @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
      100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
  }