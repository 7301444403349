  

 
  ul{
      margin-bottom: 0px;
      padding-left: 0px;
  }
 
  a:hover{
    text-decoration: none;

  }
  .dropdown-item:hover{
  
      border-radius: 8px;
      background: #F9F5FF; 
     
   
  }

  
  
  .newcontainer {
    max-width: 95%;
 
      margin: 0 auto;
      padding: 0 1rem;
  }
 

  
  
  
  .navigation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* height: 7rem; */
      position: relative;
      background: var(--white);
      /* margin-bottom: 30px; */
      
  }
  
  
  .nav_list {
      display: inline-flex;
      gap: 2rem;
      align-items: center;
      margin: 0 1.5rem;
      color: var(--Gray-600, #475467);
  
  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  }
  
  .nav_action {
      display: flex;
      align-items: center;
      gap: 1rem;
  }
  
  .nav_link
   {
      display: flex;
      justify-content: center;
      gap: 1rem;
     
      color: #101828;
      align-items: center;
      font-family: "Inter";
    


font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
  }
  
  
  /* .btn-primary:hover{
    border-radius: 8px;
      border: 1px solid var(--Primary-600, #7F56D9);
      background: var(--Primary-600, #7F56D9);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  } */
  .icn-bc{
    border-radius: 28px;
  border: 8px solid var(--Primary-50, #F9F5FF);
  background: var(--Primary-100, #F4EBFF);
  
  }
    /* .btn-primary {
      display: inline-flex;
      color: var(--white);
      background: var(--primary);
      padding: 16px 20px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--Primary-600, #7F56D9);
      background: var(--Primary-600, #7F56D9);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: var(--Base-White, #FFF);
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
  } */
  
  
  .nav_toggle {
      cursor: pointer;
      display: none;
  }
  
  .nav_toggle ion-icon {
      font-size: 3.5rem;
      color: var(--text-primary);
  }
  .item-heading{
      color: var(--Primary-600, #7F56D9);
  
  /* Text sm/Semibold */
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  }
  .dropdown-header {
      position: absolute;
      top: var(--nav-height);
      left: 0;
      width: 100%;
      background: var(--dropdown-bg);
      box-shadow: 0rem 0.2rem 0.5rem var(--shadow);
      clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
      transition: all 0.5s ease-in;
      border-radius: 10px;
      border-radius: 12px;
      border: 1px solid var(--Gray-200, #EAECF0);
      background: var(--Base-White, #FFF);
      box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  }
  
  .dropdown-header-inner {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
      gap: 1rem;
      padding: 2rem;
  }
  
  .nav_list_menu:hover .copyright-text {
      transition: all 0.5s ease-in;
      transform: rotate(180deg);
  }
  
  .nav_list_menu:hover .dropdown-header  {
      clip-path: polygon(0 0, 100% 0, 100% 102%, 0 102%);
  }
  
  .item-list {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 3rem 0;
  }
  
  .item-img {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F4EBFF;
  }
  
  .item-list-info {
      position: relative;
      width: 100%;
  }
  .item-list-info h4{
      color: var(--Gray-900, #101828);
  
  /* Text md/Semibold */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  }
  .item-list-info p{
      color: var(--Gray-600, #475467);
  
  /* Text sm/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  }
  
  .info-badge {
      position: absolute;
      right: 1rem;
      top: 0;
      background: var(--badge-bg);
      padding: 0.1rem 0.5rem;
      border-radius: 1rem;
      color: var(--badge-text);
  }
  
  
  
  .dropdown-header  {
    position: absolute; /* Set the position of the dropdown */
    top: 100%; /* Position it below the parent */
    left: 0;
    z-index: 1000; /* Set a higher z-index to ensure it appears above other elements */
    /* Hide the dropdown by default */
  }
  
  
  
  
  @media (max-width:730px) {
      .nav_toggle {
          display: block;
      }
      .nav_menu {
          position: absolute;
          top: var(--nav-height);
          left: 0;
          width: 100%;
          background: var(--dropdown-bg);
          display: none;
      }
      .nav_menu.active {
          display: block;
      }
      .nav_list {
          display: block;
          margin: 2rem 0;
          text-align: center;
      }
      .nav_link {
          padding: 0 2rem;
          display: flex;
          justify-content: space-between;
      }
      .dropdown-header  {
          top: 0;
          position: relative;
          clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
          box-shadow: none;
          height: 0;
          text-align: start;
          transition: all 0.5s ease-in;
      }
      .nav_list_menu:hover .dropdown-header  {
          height: 100%;
          transition: all 0.5s ease-in;
      }
  }
  
  @media (max-width:365px) {
      .logo-img {
          width: 10rem;
      }
      .btn,
      .btn-primary {
          padding: 0.4rem 1rem;
      }
  }