.card-action-icon svg{
    width: 35px;
    cursor: pointer;
}
.user-profile{

    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.11);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.profile-lg{
    width: 70px;
    height: 70px;
    font-size: 20px;
    font-weight: 600;
}
.profile-md{
    width: 32px;
    height: 32px;
    font-size: 12px;
    font-weight: 400;
    background-color: #e8e8e8;
    color: #333;
}
.profile-sm{
    width: 35px;
    height: 35px;
    font-size: 13px;
    font-weight: 600;
}
.user-profile img{
    width: 100%;
}

.forum-reply-profile{
    padding-right: 10px;
}
.activeBox-div{
    width: 14px;
    height: 14px;
    border-radius: 2px;
}
.on-going .activeBox-div{
    background: #FFCCE5;
    border: 1px solid #FF007C;
}
.delayed .activeBox-div{
    background: #BEBDBC;
    border: 1px solid #3D3D3D;
}
.upcoming .activeBox-div{
    background: #E5CCF0;
    border: 1px solid #7D00B5;
}
.complete .activeBox-div{
    background: #0AADE833;
    border: 1px solid #00CCF2;
}

/* switch */
.gen-switch {
	position: relative;
	display: inline-block;
	width: 35px;
	height: 18px;
	margin-bottom: 0px !important;
	border: 1px solid #A8A8A8;
	opacity: 1;
	border-radius: 22px;
	cursor: pointer;
}

.gen-switch input {
	display: none;
}
.gen-switch label{
	margin-bottom: 0px;
}

.gen-slider {
	position: absolute;
	cursor: pointer;
	top: 10px;
	left: 4px;
	-webkit-transition: .4s;
	transition: .4s;
}

.gen-slider:before {
	position: absolute;
	content: "";
	height: 12px;
	width: 12px;
	left: -2px;
	bottom: -4px;
	background-color: #00CCF2;
	transition: .4s;
}

input:checked + .gen-slider {
	background-color: #fff !important;
}

input:focus + .gen-slider {
	box-shadow: 0 0 1px #00CCF2;
}

input:checked + .gen-slider:before {
	-webkit-transform: translateX(20px);
	-ms-transform: translateX(20px);
	transform: translateX(20px);
	left: -5px;
	background-color: #49167E;;

}


.gen-slider.round {
	border-radius: 22px;
}

.gen-slider.round:before {
	background-color: #9f9f9f;
	border-radius: 50%;
}

@media (min-width: 1200px){
.modal-xl {
    max-width: 90%;
}
}

.ass-archive{
    cursor: pointer;
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #d0d5dd);
    background: var(--Base-White, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--Gray-700, #344054);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}