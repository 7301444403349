.lab {
  height: 100vh;

  overflow-y: scroll;
}
.lab::-webkit-scrollbar
{
width: 6px;
background-color: #F5F5F5;
}

.mainbody{
height: 100vh;
}

.labbody {
display: flex;
flex-wrap: wrap;

}
/* .labbaodyscreen{
height: 100vh;
} */

.mah{
  position: absolute;

}
.S {
position: absolute;
margin: 30% 30%;
display: block;
}

#circle {
width: 200px;
height: 200px;
color: #fff;
/* background: linear-gradient(-45deg, #bbbcbc, #F3F3F3, #bbbcbc); */
background-color: #764BC4;
background-size: 400% 400%;
-webkit-animation: Gradient 5s ease infinite;
-moz-animation: Gradient 5s ease infinite;
animation: Gradient 5s ease infinite;
-moz-border-radius: 50vh;
-webkit-border-radius: 50pvh;
border-radius: 50vh;
position: relative;
margin: auto;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
font-size: 18px;
font-weight: bold;
}
/* #circle .ripple {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(239, 164, 163, 0.3) 20%, transparent 70%);
  animation: rippleAnimation 2s linear infinite;
  transform: scale(0);
}

@keyframes rippleAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
} */

@-webkit-keyframes Gradient {
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
}
@-moz-keyframes Gradient {
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
}
@keyframes Gradient {
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
}
.newtext {
position: relative;
top: 26%;
left: 0;
color:  rgb(71, 21, 121);

margin: auto;
}

h2 {
text-align: center;
}

.words-wrapper {
display: inline-block;
position: relative;
text-align: center;
}
.words-wrapper b {
opacity: 0;
display: inline-block;
position: absolute;
white-space: nowrap;
left: 0;
top: 0;
font-weight: 200;
}
.words-wrapper .is-visible {
position: relative;
opacity: 1;
-webkit-animation: push-in 0.1s;
-moz-animation: push-in 0.1s;
animation: push-in 0.1s;
}
.words-wrapper .is-hidden {
-webkit-animation: push-out 0.1s;
-moz-animation: push-out 0.1s;
animation: push-out 0.1s;
}

@-webkit-keyframes push-in {
0% {
  opacity: 0;
  -webkit-transform: translateY(-100%);
}
70% {
  opacity: 1;
  -webkit-transform: translateY(10%);
}
100% {
  opacity: 1;
  -webkit-transform: translateY(0);
}
}
@-moz-keyframes push-in {
0% {
  opacity: 0;
  -moz-transform: translateY(-100%);
}
60% {
  opacity: 1;
  -moz-transform: translateY(10%);
}
100% {
  opacity: 1;
  -moz-transform: translateY(0);
}
}
@keyframes push-in {
0% {
  opacity: 0;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateXY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
60% {
  opacity: 1;
  -webkit-transform: translateY(10%);
  -moz-transform: translateY(10%);
  -ms-transform: translateY(10%);
  -o-transform: translateY(10%);
  transform: translateY(10%);
}
100% {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
}
@-webkit-keyframes push-out {
0% {
  opacity: 1;
  -webkit-transform: translateY(0);
}
60% {
  opacity: 0;
  -webkit-transform: translateY(110%);
}
100% {
  opacity: 0;
  -webkit-transform: translateY(100%);
}
}
@-moz-keyframes push-out {
0% {
  opacity: 1;
  -moz-transform: translateY(0);
}
60% {
  opacity: 0;
  -moz-transform: translateY(110%);
}
100% {
  opacity: 0;
  -moz-transform: translateY(100%);
}
}
@keyframes push-out {
0% {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
60% {
  opacity: 0;
  -webkit-transform: translateX(110%);
  -moz-transform: translateY(110%);
  -ms-transform: translateY(110%);
  -o-transform: translateY(110%);
  transform: translateY(110%);
}
100% {
  opacity: 0;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
}
}
.loader {
color: #fff;
font-family: Consolas, Menlo, Monaco, monospace;
font-weight: bold;
font-size: 30vh;
opacity: 0.8;
}
.loader span {
display: inline-block;
-webkit-animation: pulse 0.4s alternate infinite ease-in-out;
        animation: pulse 0.4s alternate infinite ease-in-out;
}
.loader span:nth-child(odd) {
-webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
}
@-webkit-keyframes pulse {
to {
  transform: scale(0.8);
  opacity: 0.5;
}
}
@keyframes pulse {
to {
  transform: scale(0.8);
  opacity: 0.5;
}
}

#timer {
  position: relative;
  width: 150px;
  height: 150px;
  margin-left: 80px;
}

#timer .circle {
  fill: transparent;
  stroke: rgb(71, 21, 121);
  stroke-width: 10;
  stroke-dasharray: 628;
  stroke-dashoffset: 0;
  animation: timerAnimation 60s linear forwards;
}

#timer .fill {
  fill: rgb(71, 21, 121);
  animation: fillAnimation 60s linear forwards;
}

#timer .text {
  fill: #FFF;
  font-family: Arial;
  font-size: 24px;
  font-weight: bold;
  text-anchor: middle;
  dominant-baseline: middle;
}

@keyframes timerAnimation {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -628;
  }
}

@keyframes fillAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.new-chat-bubble {
 
  /* padding:16px 28px; */
  /* -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px; */
  display:inline-block;
}
.typing {
  align-items: center;
  /* display: flex; */
  /* height: 17px; */
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #fff ; 
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color:#fff; 
  }
  28% {
    transform: translateY(-7px);
    background-color:#fff; 
  }
  44% {
    transform: translateY(0px);
    background-color: #fff; 
  }
}

#container{
  /* border:1px solid red; */
  border-radius: .5em;
  padding:10px;
}
.uploadcontainer {
  /* height: 100vh; */
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  /* background-color: #fcfcfc; */
}




.drop_box {
  margin: 10px 0;
  /* padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px dotted #a3a3a3;
  border-radius: 5px; */
  height: 150px;
	padding: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	
	border-radius: 12px;
border: 2px solid var(--Primary-600, #7F56D9);
background: var(--Base-White, #FFF);
}

.drop_box h4 {
  font-size: 16px;
  font-weight: 400;
  color: #2e2e2e;
}

.drop_box p {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #a3a3a3;
}



 /* upload file */
 .drop-zone {
	
	height: 150px;
	padding: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	
	border-radius: 12px;
border: 2px solid var(--Primary-600, #7F56D9);
background: var(--Base-White, #FFF);
  }

  .dropzone_icon{
	border-radius: 8px;
border: 1px solid var(--Gray-200, #EAECF0);
background: var(--Base-White, #FFF);

margin: auto;
display: flex;
width: 40px;
height: 40px;
padding: 10px;
justify-content: center;
align-items: center;


/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  
  .drop-zone--over {
	border-style: solid;
  }
  
  .drop-zone__input {
	display: none;
  }
  
  .drop-zone__thumb {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	overflow: hidden;
	background-color: #cccccc;
	background-size: cover;
	position: relative;
  }
  
  .drop-zone__thumb::after {
	content: attr(data-label);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 5px 0;
	color: #ffffff;
	background: rgba(0, 0, 0, 0.75);
	font-size: 14px;
	text-align: center;
  }
  .click{
	color: var(--Primary-700, #6941C6);

/* Text sm/Semibold */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
  }
  .drop-zone__prompt{
	color: var(--Gray-600, #475467);

/* Text sm/Regular */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
  }








/* new lab design css */

.mainlabscreen{
  overflow-y: scroll;
}
.mainlabscreen::-webkit-scrollbar{
display: none;
}




.task{
  display: inline-flex;

padding: 16px 24px;
flex-direction: column;
align-items: flex-start;
flex-shrink: 0;
background-color: #FCFCFD;
overflow-y: scroll;
height: 100vh;
}
.task::-webkit-scrollbar{
  display: none;
}
.taskheader{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  padding: 10px 10px;
}
.taskheader-taskname{
  overflow: hidden;
    color: #101828;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

}
.taskfooter{
  display: flex;
padding: 16px 24px;
justify-content: space-between;
align-items: center;
align-self: stretch;
border-top: 1px solid  #EAECF0;

text-align: center;
/* position: absolute;
 
    bottom: 0;  */

}
.taskfooter-pagination{
  display: flex;
padding: 12px 16px;
justify-content: space-between;
align-items: center;
align-self: stretch;
}
.taskfooter-pagination-text{
  color:  #344054;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
text-align: center;
}
.ftr-icn{
  border-radius: 8px;
border: 1px solid  #D0D5DD;
background:  #FFF;
text-align: center;
padding: 12px 16px;


/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.assessment-objective-parrent{
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
background:  #F9F5FF;
padding: 16px;

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.assessment-objective{
  color:  #101828;

  /* Text lg/Semibold */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
}
.assement-objective-collpase-icon{

}
.assessment-objective-content{
  color:  #344054;
  align-self: stretch;
/* Text md/Regular */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
border-bottom-left-radius: 8px;
border-bottom-right-radius: 8px;
background:  #F9F5FF;
padding: 16px;
text-align: justify;
/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}


/* vm part  */
.vmstart-button{
  border-radius: 8px;
border: 1px solid var(--Primary-600, #7F56D9);
background: var(--Primary-600, #7F56D9);
padding: 10px 16px;
justify-content: center;
align-items: center;
/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: var(--Base-White, #FFF);

/* Text md/Semibold */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
}
.btntimer{
  border-radius: 8px;
border: 1px solid var(--Gray-300, #D0D5DD);
background: var(--Base-White, #FFF);
padding: 10px 16px ;
/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: var(--Gray-700, #344054);

/* Text md/Semibold */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
}

.vmcontainer{
  border-radius: 8px;
background:  #F4EBFF;
/* padding: 0px 371px 0px 370px; */
/* Shadow/md */
box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
}
.vmloading{
  color: var(--Gray-700, #344054);
text-align: center;

/* Text lg/Medium */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 28px; /* 155.556% */
}
.vmloader-text{
  color: var(--Gray-600, #475467);
text-align: center;
margin-top: 10px;
/* Display sm/Medium */
font-family: Inter;
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: 38px; /* 126.667% */
}
.labstepsparent{
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 12px;
}
.labstepsheading{
  color: var(--Gray-900, #101828);

/* Text lg/Semibold */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
}
.labsteps{
  color:  #344054;

/* Text md/Regular */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
text-align: justify;
}


/* compile button section  */ 


.main-compile-section{
  /* display: flex; */

height: 72px;
padding: 16px 24px 16px 0px;

align-items: center;
gap: 12px;
align-self: stretch;
/* border-bottom: 1px solid var(--Gray-200, #EAECF0); */
background: var(--Base-White, #FFF);
}
.main-compile-section-heading{
  color: var(--Gray-600, #475467);

/* Text sm/Medium */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
}

.task-perforamnce-name{
  color: var(--Gray-900, #101828);

/* Text md/Medium */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}

.pass-task{
  display: flex;
padding: 2px 10px 2px 8px;
align-items: center;
gap: 4px;
border-radius: 16px;
border: 1px solid var(--Success-200, #ABEFC6);
background: var(--Success-50, #ECFDF3);
mix-blend-mode: multiply;
color: var(--Success-700, #067647);
text-align: center;

/* Text sm/Medium */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
}

.fail-task{
  display: flex;
padding: 2px 10px 2px 8px;
align-items: center;
gap: 4px;
border-radius: 16px;
border: 1px solid var(--Success-200, #ABEFC6);
background: var(--Success-50, #ECFDF3);
mix-blend-mode: multiply;

text-align: center;

/* Text sm/Medium */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
border-bottom: 1px solid var(--Gray-200, #EAECF0);
background: var(--Gray-50, #F9FAFB);
border-radius: 16px;
border: 1px solid var(--Error-200, #FECDCA);
background: var(--Error-50, #FEF3F2);

color: var(--Error-700, #B42318);



}








