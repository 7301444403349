@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap");

/* primary */
$moderate-blue: hsl(238, 40%, 52%);
$soft-red: hsl(358, 79%, 66%);
$light-grayish-blue: hsl(239, 57%, 85%);
$pale-red: hsl(357, 100%, 86%);

/* neutral */
$dark-blue: hsl(212, 24%, 26%);
$grayish-blue: hsl(211, 10%, 45%);
$light-gray: hsl(223, 19%, 93%);
$very-light-gray: hsl(228, 33%, 97%);
$white: hsl(0, 0%, 100%);

/* typography */
$ff-rubik: "Rubik", sans-serif;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;
