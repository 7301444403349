.loading {
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
  background-color: #124c62;
  border-radius: 100%;
  height: 6em;
  width: 6em;
}

.load {
  align-items: center;
  /* background-color: white; */
  display: flex;
  height: 90vh;
  justify-content: center;
  /* width: 100vw; */
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0.5;
    transform: scale(1);
  }
}