.report-container{
    width: 100%;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.116);
    display: flex;
}
.chart-report{
    padding: 20px 10px;
    width: 100%;
}
.report-action{
    width: 370px;
    background: #f9f9f9;
    border: 1px solid #ececec;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.action-list{
    border-bottom: 1px solid #ddd;
    display: flex;
    padding: 20px 15px;
    justify-content: space-between;
}
.action-list path{
    fill: #00CCF2;
}
.action-list .title-lg{
    color: #00CCF2;
}


/* table {
   
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #70707030;
    

   
  } */
 
  .c td, th {
    /* border: 1px solid #70707030; */
    text-align: center;
    /* color: #F1844D; */
    padding: 8px;
    font-size: 16px;
    /* font-family: Georgia, Bold; */
  }
  .c tr {
      background-color: white;
      font-size: 16px;
      /* font-family: Georgia, Bold; */
      /* border: 1px solid #707070; */
  }
  .table-responsive {
    min-height: .01%;
    overflow-x: auto;
    /* background-color: #49167E; */
    /* margin-right: -30px; */
   /* margin-left: 20px;
    margin-right: 20px; */
    padding: 20px;
    border-radius: 10px;
}


  /* .btnmdl{
    background-color:#F1844D;
    border: none;
    color: white;
    padding: 10px 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 90px;
    height: 40px;
    border-radius: 6px;
    margin-left: 130px;

  } */
 


  
    
    
@media screen and (max-width: 767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #707070;
    }
    .table-responsive > .table {
        margin-bottom: 0;
    }
    .table-responsive > .table > thead > tr > th,
    .table-responsive > .table > tbody > tr > th,
    .table-responsive > .table > tfoot > tr > th,
    .table-responsive > .table > thead > tr > td,
    .table-responsive > .table > tbody > tr > td,
    .table-responsive > .table > tfoot > tr > td {
        white-space: nowrap;
    }
    .maindiv{
        display: contents;
        flex-direction: column;
    }
    .lbl{
        margin-bottom: 5px;
    }
  
}



.react-calendar-heatmap .color-github-4 {
    fill: #49167E !important;

}
.react-calendar-heatmap .color-github-1 {
    fill: #8666AB!important;
   
}

.react-calendar-heatmap .color-github-2 {
    fill: #cfb2f1!important;
   
}
.react-calendar-heatmap .color-github-3 {
    fill: #8964b4!important;
   
}
.has-tooltip {
    position: relative;
    cursor: pointer;
  }
  .has-tooltip:hover:after {
    content: attr(data-tip);
    position: absolute;
    top: -40px;
    left: -40px;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    background-color: #000;
    color: #fff;
    white-space: nowrap;
    z-index: 99999;
  }

  .presentstatus {
    background: #c4b1d9;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: default!important;
    display: inline-block;
    /* font-size: 13px; */
    /* margin: 0 18px 5px; */
    padding: 4px 8px;
    color: green;
}



/* NEW REPORT LAYOUT CSS */
.main-report{
    background: rgb(249, 250, 251);
    border-radius: 8px;
    border: 1px solid rgb(234, 236, 240);
    box-shadow: rgba(16, 24, 40, 0.06) 0px 1px 2px 0px, rgba(16, 24, 40, 0.1) 0px 1px 3px 0px;
}

/* datagrid table  */


/* .name-column--cell{
    box-shadow: 2px 2px 2px 2px #000 !important;
    position: sticky !important;
} */
/* .name-column--cell:first-child {
    position: sticky !important;
    left: 0 !important;
    z-index: 2 !important;
  } */
.css-ptkaw2-MuiDataGrid-root {
    --unstable_DataGrid-radius: 4px;
    --unstable_DataGrid-headWeight: 500;
    --unstable_DataGrid-overlayBackground: rgba(255, 255, 255, 0.38);
    --DataGrid-cellOffsetMultiplier: 2;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    box-sizing: border-box;
    position: relative;
    /* border-width: 1px; */
    /* border-style: solid; */
    /* border-color: rgba(224, 224, 224, 1); */
    border-radius: var(--unstable_DataGrid-radius);
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    outline: none;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-width: 0;
    min-height: 0;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow-anchor: none;
    border-radius: 12px !important;
    border: 1px solid  #EAECF0 !important;
    background:  #FFF !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}
.css-yrdy0g-MuiDataGrid-columnHeaderRow{
    background: #f6f6f685 !important;
}
.css-t89xny-MuiDataGrid-columnHeaderTitle {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color:  #475467 !important;

    /* Text xs/Medium */
    font-family: 'Inter' !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px; /* 150% */
}
.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    break-inside: avoid;
    border-bottom: 1px solid var(--Gray-200, #EAECF0) !important;
    font-family: 'Inter' !important;
    font-size: 14px !important;
}
.css-1knaqv7-MuiButtonBase-root-MuiButton-root{
  
    border-radius: 8px;
border: 1px solid var(--Gray-300, #D0D5DD) !important;
background: var(--Base-White, #FFF) !important;
padding: 10px 16px !important;
color: var(--Gray-700, #344054) !important;

/* Text sm/Semibold */
font-family: 'Inter' !important;
font-size: 14px !important;
font-style: normal !important;
font-weight: 600 !important;
line-height: 20px !important; /* 142.857% */
/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
text-transform: none !important;
margin-bottom: 5px !important;
}


/* react tag */
div.ReactTags__tagInput input.ReactTags__tagInputField, div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 40px !important;
    margin: 10px !important;
    font-size:  20px !important;
    width: 100%;
    /* border: 1px solid #eee; */
    padding: 10px 10px !important;
    /* border: 1px solid #ced4da; */
    /* border-radius: 30px; */
    border-radius: 0% !important;
    outline: none !important;
}

div.ReactTags__selected span.ReactTags__tag{
    /* border: 1px solid #ddd;
    background: #c4b1d9;
    font-size: 13px;
    display: inline-block;
    padding: 10px;
    margin: 0 5px 5px 5px;
    cursor: default !important;
    border-radius: 12px; */

    padding: 10px 18px !important;
    background-color: rgb(208, 213, 221) !important;
    color: rgb(52, 64, 84) !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px 0px !important;
    border-radius: 8px !important;
    border: 1px solid rgb(208, 213, 221) !important;

}
.ReactTags__remove{
    padding: 0px 0px 0px 10px !important;
}



.p-30{
    padding: 30px;
}

.parrentreportcard{
    display: flex;

padding: 30px;
flex-direction: column;
align-items: flex-start;

}

    .reportcard {
        border-radius: 15px;
        border: 1.25px solid #EAECF0;
        background: #fff;
        box-shadow: 0px 1.25px 2.5px 0px rgba(16, 24, 40, 0.05);
        padding: 30px;
        width: 100%;
    }
    .reprtcard-icon{
        border-radius: 12.5px;
border: 1.25px solid var(--Gray-200, #EAECF0);
background: var(--Base-White, #FFF);
box-shadow: 0px 1.25px 2.5px 0px rgba(16, 24, 40, 0.05);
padding: 15px;
width: 15%;
text-align: center;
    }

    .reportcard-course {
        border-radius: 15px;
        border: 1.25px solid #EAECF0;
        background: #fff;
        box-shadow: 0px 1.25px 2.5px 0px rgba(16, 24, 40, 0.05);
        padding: 20px;
        width: 100%;
    }
    .reportcard-course .go-somewhere {
        display: none;
      }
      .reportcard-course :hover .go-somewhere {
        display: inline-block; /* or any other appropriate display value */
        cursor: pointer;
      }







    .reportcard .go-somewhere {
        display: none;
      }
      
      .reportcard:hover .go-somewhere {
        display: inline-block; /* or any other appropriate display value */
        cursor: pointer;
      }
   

.reportcardheader{
    color: var(--Gray-900, #101828);

/* Text lg/Semibold */
font-family: "Inter";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
padding-bottom: 10px;
}
.reportcardtitle{
    color: var(--Gray-600, #475467);

/* Text md/Regular */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}


.bbottom{
    border-bottom: 1px solid var(--Gray-200, #EAECF0);
   
}
.specificreportheader{
    color: var(--Gray-900, #101828);

/* Display sm/Semibold */
font-family: "Inter";
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: 38px; /* 126.667% */
}

.emailbtn {
    border-radius: 8px;
border: 1px solid var(--Primary-200, #E9D7FE);
background: var(--Primary-50, #F9F5FF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

padding: 10px 16px;
justify-content: center;
align-items: center;
color: var(--Primary-700, #6941C6);

/* Text sm/Semibold */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
}

.exportbtn{
      border-radius: 8px;
        color: #fff;
        border: 1px solid #7F56D9;
        background: #7F56D9;
        padding: 10px 16px;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        /* font-weight: 600; */
        line-height: 20px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        /* width: 100%; */
    }



