
.catalogBox{
    /* padding: 20px; */
    /* display: flex; */
    background-color: #fff;
    /* color: white; */
    width: 32%;
    min-width: 280px;
    /* height: 300px; */
    margin-bottom: 20px;
    justify-content: space-between;
    /* box-shadow: 0px 3px 6px #272d3b33; */
    border-radius: 20px;
    border: 1px solid #ddd;
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
margin-left: 12px;
}

.catalogBox:hover{ 
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
 }



.catalog-content{
      padding: 20px;

    background: #ececec;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.catalogBox svg{
    width: 77px;
    height: 90px;
}
.imprtbtn{
    border: none;
}
.catalog-container{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    margin-top: 10px;
}
.catalogBox-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.secondary-color {
    background: #00ccf2;
  }
  .labList{
      width: 100%;
      padding: 15px;
      display: flex;
      background-color: #ffffff;
      height: 135px;
      margin-bottom: 20px;
      justify-content: space-between;
      border: 1px solid #ddd;
      border-radius: 5px;
  }
  .labList:hover{
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    background: #ececec;
  }
  .labList-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .labList svg{
    width: 60px;
    height: 90px;
}
.star-icon{
    display: flex;
}
.star-icon svg{
    fill: #E3C52F;
    width: 30px;
    height: 25px;
}
.carousel-item img{
    width: 269px;
}
.carousel-control-next, .carousel-control-prev{
    opacity: 1;
}
.carousel-control-prev-icon, .carousel-control-next-icon{
    background: #00ccf2;
    opacity: 1;
    height: 40px;
    display: flex;
    border-radius: 50%;
    width: 40px;
    align-items: center;
    justify-content: center;
    
}
.carousel-control-next, .carousel-control-prev{
    width: 8%;
}
.carousel-control-prev-icon::after{
    content: '<';
    font-weight: 600;
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    top: 10px;
    font-family: monospace;
    font-size: 24px;
}
.carousel-control-next-icon::after{
    content: '>';
    font-weight: 600;
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    top: 10px;
    font-family: monospace;
    font-size: 24px;
}
.carousel-div{
    padding: 0px 10px;
}

.carousel{
    /* padding: 0px 88px; */
    background: #fff;
    /* width: 100%; */
    padding-bottom: 50px;

}
.storeTab-shadow {
    border-bottom: 1px solid #ddd;
    margin-top: 20px;
}
.storeTab-shadow .tab-btn {
    max-width: 180px;
  }



  
  .labbutton{
    border-radius: 4px;
    background-color: #5ca1e1;
    border: none;
    color: #fff;
    text-align: center;
    padding: 10px;
    width: 180px;
    transition: all 0.5s;
    cursor: pointer;

  }
  .labbutton{
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  .labbutton:after {
    content: '»';
    position: absolute;
    opacity: 0;  
    font-size: 25px;
    bottom: 5px;
    right: -20px;
    transition: 0.5s;
  }
  
 
  
  .labbutton:hover:after {
    opacity: 1;
    right: 10px;
  }
  .assesmentlab{
    background: #c4b1d9;
    border: 1px solid #ddd;
    border-radius: 15px;
    cursor: default!important;
    display: inline-block;
    font-size: 13px;
    margin: 0 18px 5px;
    padding: 6px;
  }


  /* .monaco-editor .margin {
    background-color: #ececec !important;
} */
.monaco-editor .overflow-guard {
    position: relative;
    overflow: hidden;
}
.codeEditor{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.editor-tab{
    width: 425px;
    background: #00CCF2;
    border-radius: 5px;
    padding: 5px 15px;
    color: white;
    align-items: center;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}
.editor-tab-w{
    width: 150px;
    text-align: left;
}
.editor-btn{
    display: flex;
}
.editor-btn div{
        padding: 0px 5px;
        cursor: pointer;
}
.rotate-45{
    transform: rotate(45deg);
}
.color-blue{
    color: #0e4384;
} 
.editor-output{
    width: 100%;
    height: 100px;
    color: #008016;
    text-align: start;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 10px;
}
.editor-output .form-control {
    width: 100%;
    border: 0px;
}
.editor-output .form-control:focus{
    box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}
.editor-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
}

.outputList{
    border-radius: 5px;
    border-top: 1px solid #ddd; ;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 2px solid red;
}


.scrollable-content-coding-assessment {
    background: #000a2008;
    overflow-y: scroll; /* Enable vertical scrolling */
    height: 100vh;/* Set maximum height to 100% of the viewport height */
    white-space: pre-wrap;
    word-wrap: break-word;
}
  .codingassementquestiondescription{
    /* background-color: #000a2008; */
    border-color: #0000000d;
    border-radius: 5px;
    border-width: 1px;
    color: #262626bf;
    font-family: Menlo,sans-serif;
    font-size: 16px;
/* line-height: 35px; */
    padding: 10px;
    white-space: pre-wrap;
    text-align: justify;
    word-wrap: break-word;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    scrollbar-width: thin;

  }
  .codingassementquestiondescription-fullscreen{
    background-color: aliceblue;
    border-color: aliceblue;
    border-radius: 5px;
    border-width: 1px;
    color: #262626bf;
    font-family: Menlo,sans-serif;
    font-size: 18px;
/* line-height: 35px; */
    padding: 18px;
    margin: 10px;
    white-space: pre-wrap;
    text-align: justify;
    word-wrap: break-word;
    height: 100vh;
    /* max-height: 100vh; */
    overflow-y: scroll;
    scrollbar-width: thin;

  }
  .codingassementquestiondescription::-webkit-scrollbar
{
width: 6px;
background-color: #F5F5F5;
}
  .compilebuttoncoding{
   
   
    background-color: transparent;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
  
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
  
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
  
    padding: 5px 15px;
    border-radius: 4px;
  
    border: 1px solid rgba(25, 118, 210, 0.5);
    color: rgb(25, 118, 210);

  }
  .activebtnnumber{
    background-color: #49167E !important;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
  
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
  
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
  
    padding: 5px 15px;
    border-radius: 4px;
  
    border: 1px solid #49167E;
    color: #fff;
    
  }
  .activesuccesse{
    background-color: rgb(25, 118, 210);
    outline: 0px;
    margin: 0px;
    cursor: pointer;
  
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
  
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
  
    padding: 5px 15px;
    border-radius: 4px;
  
    border: 1px solid rgba(25, 118, 210, 0.5);
    color: #fff;
  }
  .split {
    display: flex;
    flex-direction: row;
}

.gutter {
    background-color: #eee;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
}

.cancelButtonModal{
    background-color: #FD6060;
    color: #fff;
    border: 1px solid#FD6060;
    font: normal normal 600 12px/15px Montserrat;
    text-transform: uppercase;
    width: 91px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    cursor: pointer;
  }