/* .showbookmarkicon{
    margin-left: 40%;
    margin-top: -20px;
    cursor: pointer;
} */
.bookmarkcard{
    cursor: pointer;
}
.bookmarktitle{
    overflow-wrap: break-word;
}
.modal-container-bookmark {
    position: absolute;
    background: #ffffff;
    left:5%;
  
    top: 2%;
  
   
    width: 90%;
    border-radius: 15px;
    height: 90%;
}