div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {

    display: inline-block;
    width: 100%;
    padding: 3px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: rgb(248, 250, 251);
    background-clip: padding-box;

}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    /* border: 1px solid #eee; */
    padding: 0 4px;
    border: 1px solid #ced4da;
    border-radius: 30px;

}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #c4b1d9;
    font-size: 13px;
    display: inline-block;
    padding: 6px;
    margin: 0 5px 5px 5px;
    cursor: default !important;
    border-radius: 15px;
}

div.ReactTags__selected a.ReactTags__remove {
    color: #9c9c9c;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
    color: #000 !important;
    font-weight: normal !important;
    font-size: 14px !important;
}

div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0, 0, 0, .2);
    background: white;
    width: 200px;
}

div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 15px 10px;
    margin: 0;
}

div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

.quill  {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    margin: 0px 5px 10px 5px;
    height: 500px;
}

.ql-toolbar.ql-snow {
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 0px;
    min-height: 250px;
}

.ql-container.ql-snow {
    border: 1px solid #ccc;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.category_tags {
    padding: 10px 18px !important;
    /* background-color: rgb(208, 213, 221) !important; */
    color: rgb(52, 64, 84) !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px 0px !important;
    border-radius: 8px !important;
    border: 1px solid rgb(208, 213, 221) !important;
    background: #c4b1d9;
    display: inline-block;
    margin: 0 5px 5px 5px;
    cursor: default !important;
   
}