/* .our-faq-wrapper {
    float: left;
    width: 100%;
    padding: 8px 0 60px;
    background:#f5f5f5;
  } */
  .newcontiner{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .our-faq-wrapper {
      /* padding: 10px 0 60px; */
    background:#fff;  
  }
  .accordion .card {
    border: none;
    margin-bottom: 30px;
  }
  .our-faq-wrapper .btn-link {
    display: block;
    width: 100%;
    text-align: left;
    position: relative;
    background: #49167E;
    color: #fff;
    border-radius: 0;
    padding: 7px 15px 12px;
    font-size: 16px;
    overflow: hidden;
    border: none;
    font-weight:600;  
  }
  .our-faq-wrapper .btn-link:hover, .our-faq-wrapper .btn-link:focus {
    text-decoration: none;
  }
  .our-faq-wrapper .btn-link:after {
    position: absolute;
    content: '\f068';
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: fontawesome;
  }
  .our-faq-wrapper .btn-link.collapsed:after {
    content: '\f067';
  }
  .our-faq-wrapper .btn-link:before {
    position: absolute;
    content: '';
    background: #C8A3DF;
    -webkit-transform: skew(-35deg);
    transform: skew(-35deg);
    height: 100%;
    width: 50%;
    left: 90%;
    top: 0;
  }
  .card-header-faq {
    padding: 0;
    background: transparent;
    border-bottom: none;
  }
  .card-body-faq {
    position: relative;
  }
  .card-body-faq:after {
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    left: 0;
    bottom: 0;
    background: #BFCBF7;
  }
  .card-body-faq:before {
    position: absolute;
    content: '';
    height: 2px;
    width: 50%;
    left: 0;
    bottom: 0;
    background: #BFCBF7;
    z-index: 1;
  }
  .element-with-two-color-border {
  
   
    border-right: 2px solid #C8A3DF; /* Start with a transparent border */
    
  }

  