.class-mode{
    width: 160px;
    padding: 5px 10px;
    font-size: 14px;
    text-align: center;
    margin-left: 0px;
    cursor: pointer;
    background-color: #00CCF2;
    box-shadow: 1px 1px 3px #00000094;
    color: white;
    position: relative;
    font-weight: 500;
}
.class-lab{
    box-shadow: 0px 3px 6px #00000029;
    width: 100%;
    height: 100%;
}
.mode-close{
    position: absolute;
    right: 2px;
    top: 5px;
    color: black;
}
.mode-close svg{
    width: 20px;
}
.video-container{
    width: 100%;
    height: 86vh;
    box-shadow: 0px 1px 4px 0px #0000003d;
    border-radius: 10px;
    margin-top: 70px;
    overflow: hidden;
    position: relative;
}

.expZoom{
    position: absolute;
    z-index: 2;
    /* top: 26px; */
}

.video-container img{
    width: 100%;
}
.video-action{
    width: 100%;
    position: relative;
}
.plus-btn{
    width: 40px;
    margin-left: 0px;
    text-align: center;
    cursor: pointer;
    background-color: #9A9A9A;
    box-shadow: 1px 2px 1px #0000006e;
    color: white;
    position: relative;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    justify-content: center;
}

.media-link .btn svg{
    width: 20px;
}
.media-link .chat-send{
    width: 400px;
}
.media-link input[type="file"] {
    display: none;
}
.custom-file-upload {
    display: flex;
    justify-content: space-between;
    padding: 0px 8px;
    cursor: pointer;
    font-size: 14px;
    width: 100%;
    text-align: right;
    margin-bottom: 0px;
}
.media-link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}
.img-view{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: auto;
}
.img-view img{
    width: 100%;
    height: 100%;
    position: unset !important;
}
.footer-video-action{
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.stretch-content{
    position: unset !important;
}
.attachment-viewer{
    height: auto !important;
}
.docs-viewer-wrapper{
    overflow: unset !important;
}
.active-tab-class{
    background: #62a6ec !important
}