/* timeline */
.date-timeline{
    display: flex;
    width: 100%;
    padding: 20px 0px;
}
.time-title{
    font-size: 12px;
    width: 91px;
    color: #030303;
    align-items: center;
    display: flex;

}
.borderLing{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;

}
.line{
    width: 100%;
    height: 1px;
    background: #ddd;
}
.timeline-bubble{
    width: 180px;
    height: 44px;
    border-radius: 10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bubble-blue{
    border: 1px solid #00CCF2;
    background: #0AADE833;
}
.bubble-red{
    border: 1px solid #FF007C;
    background: #FF007C33;
    left: 125px;
}
.bubble-db{
    border: 1px solid #7D00B5;
    background: #7D00B533;
    left: 140px;
}