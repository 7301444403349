.nav-title-ts {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    padding: 15px;
    letter-spacing: 0.2px;
   
}



.sideBarNav{
/*    
    width: 100px; */
    height: 100%;
    background: #49167E;
    overflow-y: auto;
}

.toggle-button {
    position: fixed;
    top: 10px;
    /* right: 20px; */
    left: 20px;
    z-index: 9999;
 
    background-color: #2D0A52;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
  }

  .toggle-button-close {
    position: fixed;
    top: 10px;
    /* right: 20px; */
    left: 20px;
    z-index: 9999;
    /* background-color: #2D0A52; */
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* .toggle-button:hover {
    background-color: #f2f2f2;
  } */










  
  .sideBarNav.open {
    display: block;
    width: 250px;
    height: 100%;
    background: #49167E;
    overflow-y: auto;
    /* position: absolute; */

    left: 0;
   
    z-index: 999;
/* 
   
    transition: all .3s ease; */
    -ms-box-shadow: 1px 7px 16px rgba(0,0,0,.29);
    -o-box-shadow: 1px 7px 16px rgba(0,0,0,.29);
    box-shadow: 1px 7px 16px rgba(0,0,0,.29);
}

  
.navlist-items {
    overflow-y: auto;
    flex: 1;
}
.navbar-fixed-control {
    min-height: 50px;
    background-color: #49167E;
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    color: #fff;
    align-items: center;
}
.navbar-fixed-control:hover {
    cursor: pointer;
    background-color: #49167E;
}
.user-profile-name {
    font-size: 13px;
    font-weight: 600;
}
.user-profile-role {
    font-size: 11px;
    color: #d0d0d0;
    line-height: 14px;
}
.navMenu{
    cursor: pointer;
    font-size: 13px;
    align-items: center;
    font-weight: 600;
    padding: 0px 15px;

}

a:focus{
    outline: none !important;
}
.navMenu a {
    color: #dfdfdfc2;
    text-decoration: none;
    background-color: #3B0F69;
    padding: 5px 15px;
    margin: 8px 0px;
    transition: 500ms;
    border-radius: 8px;
    min-height: 42px;

}
.navMenu a:hover {
    /* color: #ffffff; */
    text-decoration: none;
    background-color: #3a0a6d;
    /* border-left: 3px solid; */
    transition: 500ms;
    
}
.nav-title{
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    padding: 15px;
    letter-spacing: 0.2px;
    margin-right: 40px;
   
}
.navMenu svg{
    opacity: .5;
    width: 18px;
}
.navMenu:hover svg{
    opacity: 1;
}
.sideBarNav .active a{
    color: #ffffff;
    text-decoration: none;
    background-color: transparent;
    transition: 500ms;
    background-color: #2D0A52;
    border-radius: 8px;
}
.sideBarNav .active svg{
    opacity: 1;
}