.input-field {
	width: 100%;
	border-radius: 30px !important;
	/*box-shadow: 0px 3px 6px #00000029;*/
	padding: 5px 15px !important;
	background:#f8fafb !important;
	margin-bottom: 2px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #ddd !important;
	position: relative;
}
.input-field .form-control {
	border: 0px;
	background-color: #fff0;
	color: #495057;
	outline: 0;
	box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
	outline: 0px solid #2d8bff00 !important;
    outline-offset: 0px !important;

}

.input-field input[type=text]{
	outline: none !important;
}
.input-field .form-control:focus {
	color: #495057;
	background-color: rgba(255, 255, 255, 0);
	border-color: #80bdff;
	outline: 0;
	box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
	outline: 0px solid #2d8bff00 !important;
    outline-offset: 0px !important;
}
.input-field input,
.input-field select {
	height: 30px;
}
.input-field textarea {
	height: 100px !important;
}
.input-field .react-dropdown-select-option{
	align-items: center !important;
	border-radius: 12px !important;
	color: #111 !important;
	font-size: 12px !important;
	padding: 0px 15px !important;
}
.input-field .react-dropdown-select-option-label{
	font-size: 12px;
}
.input-field .react-dropdown-select-option-remove{
	padding-top: 2px !important;
}
.input-field .react-dropdown-select-item{
	color: #111;
	padding: 5px 25px;
}
.input-field .react-dropdown-select-item-selected{
	color: #111 !important;
	padding: 5px 25px;
}
.input-wrapper {
	min-height: 70px;
}
.input-wrapper .border-danger {
	border: 1px solid #ffabb39e !important;
	box-shadow: 0px 3px 6px #dd0d0d18;
}
.input-wrapper .text-danger {
	color: #ed6774 !important;
	font-weight: 600;
	font-size: 13px;
}
.modal-content .form-group {
	margin-bottom: 0px;
}
.input-wrapper .dropdown-custom {
	border: 0px solid rgb(214, 214, 214);
	width: 100%;
}
.input-wrapper .dropdown-toggle {
	white-space: nowrap;
	display: flex;
	justify-content: space-between;
}
/* react datepickar */
.react-datepicker {
	font-family: 'Montserrat';
	font-size: 14px;
	background-color: #fff;
	color: #000;
	border: 0px solid #aeaeae;
	border-radius: 15px;
	display: inline-block;
	box-shadow: 0px 5px 13px 0px #13131329;
	position: relative;
}
.react-datepicker-wrapper{
	width: 100%;
}
.react-datepicker__header {
	text-align: center;
	background-color: transparent;
	padding-top: 8px;
	position: relative;
	border-bottom: 0px solid #aeaeae;
}
.datepicker__day,
.react-datepicker__time-name {
	color: #000;
	display: inline-block;
	width: 2.5rem;
	line-height: 2.5rem;
	text-align: center;
	margin: 0.186rem;
	border-radius: 1.8rem !important;
}
.react-datepicker__day,
.react-datepicker__day-name {
	display: inline-block;
	width: 2.2rem;
	line-height: 2.2rem;
	text-align: center;
	margin: 0.3rem;
}
.react-datepicker__day--selected {
	border-radius: 2rem;
	background: #00ccf2 !important;
	color: #fff;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
	border-radius: 1.3rem !important;
	background: #00ccf2 !important;
	color: #fff;
}
.react-datepicker__day:focus {
	outline: -webkit-focus-ring-color auto 0px;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
	border-radius: 50%;
	background-color: #f0f0f0;
	color: black;
}

.input-field .form-control:disabled, .form-control[readonly] {
    background-color: transparent;
    opacity: 1;
}
.react-datepicker__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 130px;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
	width: 100%;
}