.quill  {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    margin: 0px 5px 10px 5px;
    height: 500px;
}

.ql-toolbar.ql-snow {
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 0px;
    min-height: 250px;
}

.ql-container.ql-snow {
    border: 1px solid #ccc;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}