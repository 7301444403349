.selectButtons {
	width: 100%;
	color: #000000cf;
	padding: 4px 7px;
	/* box-shadow: 0px 0px 1px 0px #000000de; */
	font-weight: 500;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	cursor: pointer;
	border-radius: 2px;
}
.esc-multi-select-list .custom-checkbox {
	padding-top: 3px;
	padding-bottom: 3px;
	font-size: 14px;
	padding-left: 45px;
}
.esc-multi-select-list {
	margin: 0;
	list-style: none;
	padding: 5px 0 0 0;
	border: none !important;
}
.esc-multi-select-list .custom-checkbox:hover {
	background-color: #eee;
}
.esc-multi-select-list .custom-control-label {
	display: block;
	cursor: pointer;
}

.multipleDropDown {
	max-height: 300px;
	overflow-y: auto;
	width: 100%;
	padding-top: 0px !important;
}
.multipleDropDown2 {
	z-index: 5;
	height: auto;
	max-height: 300px;
	overflow-y: auto;
	width: 300px;
	background-color: #F5FBFF !important;
	display: flex !important;
	flex-direction: column !important;
	box-shadow: 0px 8px 6px #00000029;
}
.filterInput {
	margin: 14px 5%;
	width: 90% !important;
}
.dropdown-body {
	overflow-y: auto;
	/* border-top: 1px solid #e9e9e9; */
}
.input-field .dropdown{
	width: 100%;
}
.dropdown-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e9e9e9;
  padding: 10px 10px 6px 10px;
}
.dropDown-header{
  padding: 5px 5px 10px;
  font-size: 13px;
}
.dropDown-search{
    padding: 5px 10px 10px;
    align-items: center;
    font-size: 13px;
    text-align: center;
}
.dropDown-close{
  position: absolute; 
  top: 0px; 
  left: 0px;
  opacity: 0;
  pointer-events: none;
}

.multipleDropDown .form-control:focus > #selectAll {
	display: none;
}
.multipleDropDown .dropdown-menu {
	box-shadow: 0 4px 7px #9e9e9e40 !important;
	border: 0px solid rgba(0, 0, 0, .15);
}
.input-field{
    border: 0px;
    /* background: transparent; */
}
.dropdown-custom-components> .dropdown-toggle::after{
    display: none ;
}
.dropdown-custom-components .form-control:focus{
    box-shadow: 0 0 0 0px rgba(0, 123, 255, .25) !important;
}
.dropdown-custom-components {
    border: 1px solid rgb(214, 214, 214);
    padding-right: 5px;
    border-radius: 2px;
    font-size: 14px;

}
.field-tag{
    background: #e9e8e8;
    padding: 2px 10px;
    border-radius: 11px;
    margin: 4px 3px;
    font-size: 12px;
    text-transform: capitalize;
}
.field-tag svg{
	cursor: pointer;
}
.pointer{
	cursor: pointer;
}
.text-capitalize{
	text-transform: capitalize;
}
.input-container{
	display: flex;
	flex-wrap: wrap;

}
.w100px{
	width: 100px;
}
.for-tag  .dropdown-menu{
	width: 100%;
}
.dropdown-container{
	display: flex;
    height: 30px;
    align-items: center;
	width: 100%;
}