.header-title{
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    color: rgb(10, 10, 10);
}
.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 25px;
    /* box-shadow: 0px 0px 4px 0px #00000024; */
    min-height: 52px;
    z-index: 1000;
}
.header svg{
    width: 16px;
}
.breadcrumb-wrap {
    font-size: 13px;
    color: #666;
}
.breadcrumb-wrap > a {
    padding-right: 20px;
    color: #666;
    position: relative;
    cursor: pointer;
}
.breadcrumb-wrap > a::after{
    content: "/";
    color: #999999;
    position: absolute;
    right: 8px;
}
.breadcrumb-wrap > a:last-child::after{
    content: "";
}
.breadcrumb-wrap > a:last-child{
    color: #2D62ED;
}
.search-icon-wrap svg {
    width: 16px;
}
.search-box{
    display: flex;
    padding: 0 10px;
    /* box-shadow: 0px 3px 6px #00000029; */
    border-radius: 25px;
    width: 220px;
    border: 1px solid #ddd;
}

.search-box .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff00;
    outline: 0px solid #2d8cff !important;
    box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
    outline-offset: 0px !important;
}
.search-box .form-control{
    border: 0px;
    font-size: 13px;
}

.icon-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: #333333;
    background: #dddddd;
    border: none;
    outline: none;
    border-radius: 50%;
  }
  
  .icon-button:hover {
    cursor: pointer;
  }
  
  .icon-button:active {
    background: blue;
  }
  
  .icon-button__badge {
    position: absolute;
    top: -5px;
    right: -10px;
    width: 20px;
    height: 20px;
    background: red;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
