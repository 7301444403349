.board-style{
    height: 500px;
    border: 1px solid #8d8d8d45;
}
.board-menu{
    width: 40px;
    padding: 0px 0px;
    height: 100%;
    border-radius: 5px;

}
.board-btn{
    color: white;
    padding: 5px 5px;
    cursor: pointer;
}
.whiteboard{
    width: 100%;
    height: 100%;
    padding: 10px;
    
}
.board-btn-active{
    background: #00000017;
}

.wb-color input{
    border: 0px;
    width: 25px;
    height: 25px;
    background: transparent;
    cursor: pointer;
}
.wb-color input:focus{
    border: 0px;
    box-shadow: 0px 0px 0px 0px black;
    outline: 0px;
}
.board-menu-top{
    width: 250px;
    padding: 0px 0px;
    height: 100%;
    border-radius: 2px;
    background:#00000017;
    padding: 0px 10px;

}
.bdLineW{
    width: 40px;
}