.ng-table {
  border-spacing: 0 1px !important;
  font-size: 14px;
  margin-bottom: 20px;
  /* table-layout: fixed; */
  margin-bottom: 0 !important;
}
.dynamic-table-wrapper{
  overflow-x: auto;
  min-height: 175px;
  
}
.dynamic-table-wrapper-training-content{
  overflow-x: auto;
  min-height: 100%;
}
.ng-container {
  padding: 10;
  /* background: white; */
  padding: 0px 0px 0px;
  box-shadow: 0px 0px 2px 0px #00000038;
}
.ng-table > thead > tr > th {
  text-align: left;
  color: #666 !important;
  opacity: 1;
  white-space: nowrap;
  vertical-align: middle;
  /* padding: 10px 20px !important; */
  padding: 10px 10px ;
  font-weight: 600;
  font-size: 12px;
  background-color: #eee;
  border:none;
}

.ng-table1{
  border-spacing: 0 1px !important;
  font-size: 14px;
  margin-bottom: 20px;
  /* table-layout: fixed; */
  margin-bottom: 0 !important;
  border: none;
}
.ng-table1>thead>tr>th{
  
  padding: 0px;
}
.ng-table2>thead>tr>th{
  
  padding: 0px;
}
.ng-table1 > thead > tr > th {
  text-align: left;
  color: #666 !important;
  opacity: 1;
  white-space: nowrap;
  vertical-align: middle;
  /* padding: 10px 20px !important; */
  /* padding: 10px 10px ; */
  font-weight: 600;
  font-size: 12px;

  border:none;
}
.ng-table2 > thead > tr > th {
  text-align: left;
  color: #666 !important;
  opacity: 1;
  white-space: nowrap;
  vertical-align: middle;
  /* padding: 10px 20px !important; */
  /* padding: 10px 10px ; */
  font-weight: 600;
  font-size: 12px;

  border:none;
  
}



.ng-table1 > tbody > tr > td {
  
  /* padding: 10px 20px; */
  padding: 10px 24px;
  max-width: 280px;
  border-top: 1px solid #eceff1 !important;
}
.ng-table2 > tbody > tr > td {
  
  /* padding: 10px 20px; */
  /* padding: 10px 24px; */
  max-width: 280px;
  border-top: 1px solid #eceff1 !important;
}

.ng-table > tbody > tr > td {
  vertical-align: middle;
  /* padding: 10px 20px; */
  padding: 10px 10px;
  max-width: 280px;
  border-top: 1px solid #eceff1 !important;
}

.table thead th {
  vertical-align: bottom;
}
.ng-table tbody > tr {
  /* background-color: #fff; */
  border: 1px solid #ececec;
}
.ng-table ,.ng-table1,.ng-table2 tbody > tr:hover {
  background-color: #f6f6f685;
  cursor: pointer;
  transition: all 0.3s;
}



.ng-table thead th {
  vertical-align: top;
  border-top: 0px solid #dee2e6;

}
.sort-enabled > thead > tr > th:hover {
  /* background-color: #e6e6e6; */
  cursor: pointer;
  }
.active-sort{
  background-color: #a19e9e2e;
}
.action-icon {
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  line-height: 16px;
  margin-top: -5px;
}
/* checkbox css */

.table-borderless tbody > tr {
  border: 0px solid #ececec;
}
.ng-table .table-borderless > tbody > tr > td {
  padding: 5px 20px;
}
/* Custom input */
.custom-input input {
padding: 0;
height: initial;
width: initial;
margin-bottom: 0;
display: none;
cursor: pointer;
}

.custom-input label {
position: relative;
cursor: pointer;
margin: 0;
}

.custom-input label:before {
content: '';
-webkit-appearance: none;
background-color: #ffffff;
border: 2px solid #272d3b3b;
padding: 7px;
display: inline-block;
border-radius: 1px;
position: relative;
vertical-align: middle;
cursor: pointer;
}


.custom-input input:checked + label:after {
content: '';
display: block;
position: absolute;
top: 5px;
left: 6px;
width: 4px;
height: 8px;
border: solid #504848;
border-width: 0 2px 2px 0;
transform: rotate( 
45deg
);
}

/* .custom-input input:checked + label:before{
background-color: #00CCF2;
border: 1px solid #00CCF2;
} */




/* report table */

.reporttable{
  border-spacing: 0 1px !important;
  font-size: 14px;
  margin-bottom: 20px;
  /* table-layout: fixed; */
  margin-bottom: 0 !important;
 border-radius: 12px;
border: 1px solid var(--Gray-200, #EAECF0);
background: var(--Base-White, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.reporttable > thead  {
  text-align: left;
 
 
  white-space: nowrap;
  vertical-align: middle;
 
  padding: 10px 10px ;
  font-weight: 600;
  font-size: 25px;
  background-color: #F5F8FB;
  border:none !important;
  text-transform: uppercase;
}



.reporttable > thead > tr > th {
  text-align: left;

 
  white-space: nowrap;
  vertical-align: middle;
 
  padding: 10px 10px ;
  font-weight: 600;
  font-size: 12px;
  background-color: #F5F8FB;
  border:none !important;
}

.css-zpmn9m{
  padding: 0px 24px !important;
  margin-bottom: 0px !important;
}