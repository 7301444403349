/* * {
    overflow-x: hidden;
 
} */

/* .landing-bg{
    background:linear-gradient(to right, #250B3F ,#571A96)  !important;
    clip-path: ellipse(100% 60% at 50% 40%);
    
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
  }
  
  .circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  
  
  .circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }
  
  .circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }
  
  .circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }
  
  .circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }
  
  .circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }
  
  .circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }
  
  .circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }
  
  .circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }
  
  .circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }
  
   */

/* @keyframes animate {
  
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
  
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
  
  }
.landing-bg .pg-title, .landing-bg .pg-title2, .landing-bg .pg-title-sm, .landing-bg .pg-desc{
    color: rgba(255, 255, 255, 0.808) !important;
}


.nav-active{
    color: #49167E !important;
}

.section{
    min-height: 100vh;
    height: 100%;
    padding: 40px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
}
.section-dk{
    background: #F2F2F2;
}
.pg-img img{
    width: 100%;
    
}

.pg-title-sm{
    text-align: left;
    font: normal normal 300 26px/36px Open Sans;
    letter-spacing: 0px;
    color: #111111;
    opacity: 1;
    font-size: 26px;
}
.pg-title{
    font-size: 36px;
    text-align: left;
    font: normal normal bold 36px/49px Open Sans;
    letter-spacing: 0px;
    color: #111111;
    opacity: 1;
    padding: 15px 0px;
}
.pg-title2{
    font: normal normal bold 28px/38px Open Sans;
    letter-spacing: 0px;
    color: #111111;
    opacity: 1;
}
.pg-title3 {
    font: normal normal bold 20px Open Sans;
    letter-spacing: 0px;
    color: #111111;
    opacity: 1;
}
.pg-desc{
    font: normal normal normal 22px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    font-size: 16px;
    padding: 15px 0px;
}
.pg-desc1{
    text-align: center;
    font: normal normal normal 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #555555;
    opacity: 1;
}
.title-ss{
    color: #49167E;
    font-weight: 400;
    font-size: 30px;
}
.sec-about{
    text-align: center;
}
.contact-info svg{
    color: #49167E;
}
.contact-info .MuiSvgIcon-root{
    font-size: 2.5rem;
}
.context-body{
    width: 500px;
    margin: auto;
    margin-top: 35px;
    /* display: flex; */
/* align-items: center;
    justify-content: center;
    
}
.dialog-pg img{
    width: 146px;
}
/* ..........page-card........ */
/* .page-card-container{
    display:inline-flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    justify-content: center;
    gap: 35px;
    padding: 0px 80px;
} 
.page-card{
    width: 350px;
    min-width: 320px;
    background: white;
    padding: 30px 20px;
    border-radius: 0.4rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.2sec;
}
.page-card:hover{
    transform: translateY(-0.5%);
    box-shadow: 0 1rem 1rem rgba(0,0,0,0.5);
} */




.page-card-title {
    font: normal normal bold 18px/22px Open Sans;
    letter-spacing: 0px;
    color: #49167E;
    opacity: 1;
    cursor: pointer;
    margin-top: 10px;
}

.page-card-subTitle {
    font: normal bold normal 14px Open Sans;
    letter-spacing: 0px;
    color: #111111;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}

.page-card-body {
    font: normal normal normal 12px/22px Open Sans;
    letter-spacing: 0px;
    color: #333333;
}

.page- .input-wrapper {
    width: 100%;
}

.card-footer-action {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.page-input .input-field {
    border-radius: 0px;
    padding: 5px 0px;
    background: transparent !important;
    border: 0px solid !important;
    border-bottom: 1.5px solid #8282826b !important;
}

.page-input .input-field .form-control-sm {
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem 0rem;
}

.pg-footer {
    background: #333333;
}

.pg-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* height: 80px; */
    color: rgba(255, 255, 255, 0.719);
    padding: 20px 40px;
    font-size: 14px;
}

.btn-mes {
    padding: 10px 15px;
    align-items: center;
    border-radius: 2px;
    text-align: center;
    background: #F0861D;
    color: white;
}

.btn-mesR {
    width: 150px;
    padding: 8px 15px;
    align-items: center;
    border-radius: 30px;
    text-align: center;
    background: #F0861D;
    color: white;
    cursor: pointer;
}

.edu-img {
    width: 100%;
    height: 344px;
    display: grid;
    background: #F1E3FF;
    align-items: center;
    justify-content: center;
}

.edu-img img {
    width: 100%;
}

.card-footer-action svg {
    font-size: 16px;
    color: #00000073;
    margin-right: 4px;
    margin-bottom: 3px;
}

/* ------------------------------------------------------------- */



.animate-charcter
{
   /* text-transform: uppercase; */
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  display: inline-block;
      font-size: 40px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.header-img{
    filter: drop-shadow(5px 5px 5px rgb(172, 177, 214));
}

.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #ec1c1c;
    color: white;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
}

.scroll-to-top.visible {
    opacity: 1;
}






/* nav {
    height: 4rem;
    width: 100vw;
    display: 'grid';
    position: 'fixed';
    top: '50px';
    left: '0';
    margin-bottom: 10px;
    overflow-y: hidden;
} */
html,
body {
    min-width: 100%;
    overflow-x: hidden;
}

.headericon {
    mix-blend-mode: multiply;
    /* width: 150px; */
    /* height: 70px; */

}




.empower {
    background-image: radial-gradient(#ACB1D6 0px, transparent 1px);
    background-size: 10px 10px;
    background-color: aliceblue;


}

.bcimg {
    background-image: url("https://raw.githubusercontent.com/m-ksingh/WebTraining/main/src/Assets/Image/bg-sand-wave.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.navbar-collapse {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    /* height: 3rem; */

}

.navbar-nav {
    list-style: none;
    display: flex;
    gap: 3rem;
    left: 10px;
    margin-top: 10px;
    margin-left: 80px;
}

.nav_items>li {

    color: black;
    font-size: 16px;
    font: bold;
    font-weight: 600;
    text-transform: capitalize;
}

.navbar-toggler {
    border: none;
    width: 1em;
    float: right;
}

.nav_start_free_btn {
    font-size: 15px;
    font-weight: 500;
    border-radius: .5rem;
    background-color: rgb(80 90 193);
    color: white;
    padding: 10px 22px;
    margin-right: 100px;
    margin-top: 25px;
    align-items: end;
}

.bg-custom {
    background-color: rgb(80 90 193);
}

.start_free_btn {
    font-size: 15px;
    font-weight: 500;
    border-radius: .5rem;
    background-color: rgb(80 90 193);
    color: white;
    padding: 10px 22px;
    margin-top: 25px;


}

.Empower_Enterprice {
    display: flex;
    align-items: center;


}

.Empower_Enterprice_details {
    margin-left: 100px;
}


.Empower_details_heading {
    color: #1B1C31;
    font-family: Manrope;
    font-size: 38px;
    /* font-style: normal; */
    font-weight: 800;
    /* line-height: 56px; */
    /* letter-spacing: -1.44px; */
    /* margin-left: 40px; */ 
    
    margin-top: 40px;
}

.Aspects_heading {
    color: #1B1C31;
    text-align: center;
    font-family: Manrope;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1.2px;
}

.lab_exercise_heading {
    color: #1B1C31;
    font-size: 36px;
    font-family: Manrope;
    font-weight: 900;
    font: bolder;
}

.ourprocess_heading {
    color: black;
    width: 537px;
    font-family: Manrope;
    font-size: 36px;
    font-weight: 900;
    font: bolder;
    text-align: center;
}

.lab_exercise_para {
    color: #64607D;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.36px;
}

.Elevate_skills_para {
    font-size: 16px;
    color: rgb(146, 144, 144);
    width: 100%;
    text-align: center;
}

.Gathernutilise_heading {
    color: black;
    /* width: 505px; */
    font-family: Manrope;
    font-size: 36px;
    font-weight: 900;
    font: bolder;
}


.mentor_heading {
    color: black;
    /* width: 505px; */
    font-family: Manrope;
    font-size: 36px;
    font-weight: 900;
    font: bolder;
}

.Empower_details_para {
    color: #64607D;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.4px;
    margin-left: 40px;

}

.Empower_Btn {
    display: flex;
    gap: 2rem;
    margin-left: 40px;
}

.start_free_btn_inactive {
    font-size: 15px;
    font-weight: 500;
    border-radius: .5rem;
    background-color: rgb(206, 209, 237);
    color: rgb(32, 36, 84);
    padding: 10px 22px;
    margin-right: 100px;
    margin-top: 25px;
    align-items: end;
    transition: all 300ms ease;

}

.Gathernutilise_btn {
    font-size: 15px;
    font-weight: 500;
    border-radius: .5rem;
    background-color: rgb(80 90 193);
    color: white;
    padding: 10px 22px;
    margin-top: 25px;
    align-items: end;
}

.Gathernutilise_ul>li {
    text-decoration: dotted;
}

.start_free_btn_inactive:hover {
    background-color: rgb(80 90 193);
    color: white;
    opacity: .9;
}

.why_gnosis {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin: 30px 20px; */
    background-color: aliceblue;
}

.why_gnosis_tittle {
    font-size: 24px;
}

.Elevate_skills {
    color: black;
    width: 636px;
    font-size: 36px;
    font-weight: 900;
    margin-bottom: 20px;
    text-align: center;

}

.container {
    margin-top: 30px;
}

.container_row {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.card_icon {
    width: 3.5rem;
    position: relative;
    margin: auto;
    top: -25px;
}



.wide_variety {
    flex-direction: row;
}

.about_content {
    left: 0;
}

.vr_different {
    display: flex;
    flex-direction: row;
}

.about_image>img {
    width: 633px;
}

.verifies_icon {
    color: #64CCC5;
    margin-right: 5px;
}

.lab_exercise_ul {
    /* width: 100%;
    text-align: center; */
    font-size: 16px;
}

.why_choose_us {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    margin: 25px 1px;
}

.why_choose_us_img {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #c3dbfc;
    border-radius: 50%;
    margin: 5px 15px;
}

.mentor_img {
    mix-blend-mode: multiply;
}

.why_choose_us_details {
    display: flex;
    flex-direction: column;

}

.why_choose_us_header {
    color: black;
    font-size: 16px;
    font-weight: 600;
}

.why_choose_us_para {
    color: black;
    font-size: 16px;
    font-weight: 400;
    width: 401px;
}

#accordionFlushExample {
    width: 50%;
}

.ac_cordion {
    margin-bottom: 10px;
}

.about_content {
    margin-top: 20px;
}

.wrapper {
    justify-content: center;
    align-items: center;
    width: 100%;
}

.freq_asked {
    color: black;
    width: 100%;
    font-size: 26px;
    font-weight: 900;
    font: bolder;
}

.accordion {
    width: 100%;
}

.item {
    background: white;
    margin-bottom: 5px;
    padding: 10px 20px;
}

.tittle {
    color: #111111;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font: bolder;
    cursor: pointer;

}

.content {
    color: rgb(165, 161, 161);
    overflow: hidden;
    max-height: 0;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.contentshow {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.blue_Valid {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    overflow-y: hidden;
    background: rgb(91 47 216);

}



.validation_form_heading {
    color: white;
    font-size: 36px;
    font-weight: 900;
    width: 500px;
    margin: 100px auto;
}



.form_container {
    width: 70%;
    margin: 10% auto;
    background: #ffff;
}

.safron {
    width: 4rem;
    height: 4rem;
    border-top-right-radius: 100%;
    background: #FF7722;
    margin-left: 268px;
}

.blue-button {
    width: 200px;
    height: 40px;
    background: #1b171f;
    color: white;
    text-align: center;
    align-items: center;
}

.field div {
    color: black;
    /* margin: 20px; */
}


/* .ui_form div {
    margin: 10px;
    justify-content: space-between;
} */

.accordionFlushExample {
    width: 80%;
    margin: 10%;
}

.accordionquetions {
    overflow-y: hidden;
}

.ourprocess_image {
    width: 84%;
    margin: 8%;
    background-color: #F4D3D3;
}

.ourProcess_img {
    width: 2rem;
    margin-right: 10px;
}

#vectorHeader {
    position: relative;
}

#vectorcontent {
    width: 200px;
    height: 200px;
    left: 57%;
    position: absolute;
    margin-bottom: 50px;
    float: right;
}

.mentorNlearner_img {
    position: relative;
}

#mentor_img_vectorcontent {
    width: 200px;
    height: 200px;
    position: absolute;
    right: 20%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {



    .empower,
    article,
    .wrapper,
    section,
    .container-fluid,
    footer {
        overflow-x: hidden !important;
        width: 100%;
    }

    #home {
        flex-direction: column-reverse;
    }


    .empower {
        background-image: radial-gradient(#ACB1D6 0px, transparent 1px);
        background-size: 10px 10px;
        background-color: aliceblue;
    }

    nav {
        width: 100vw;
        display: 'grid';
        position: 'fixed';
        top: '50px';
        padding: 10px;
    }

    .navbar-collapse {
        display: block;
        width: 100vw;
        height: auto;
    }

    .navbar-nav {
        list-style: none;
        display: flex;
        gap: 3rem;
        left: 10px;
        margin-top: 50px;
        margin-left: 80px;
    }

    .nav_items>li {

        color: black;
        font-size: 16px;
        font: bold;
        font-weight: 600;
        text-transform: capitalize;
    }

    .nav_start_free_btn {
        font-size: 26px;
        font-weight: 500;
        border-radius: .5rem;
        background-color: rgb(80 90 193);
        color: white;
        padding: 10px 22px;
        margin-right: 100px;
        margin-top: 25px;
        align-items: end;
    }



    .Empower_Enterprice {
        width: 100%;
        height: auto;
        display: block;
        align-items: center;
    }

    .Empower_details_heading {
        font-size: 26px;
    }
    .Empower_details_para {
        font-size: 18px;
    }
    .Empower_Btn {
        justify-content: center;
        flex-direction: column;
    }

    .start_free_btn {
        font-size: 14px;
    }

    .start_free_btn_inactive {
        font-size: 14px;
        width: 100%;
        margin-top: 0px;
    }

    .why_gnosis_tittle {
        font-size: 20px;
        text-align: center;
    }

    .Aspects_heading {
        font-size: 26px;
        font-weight: 900;
    }
    
    .card_icon {
        width: 6rem;
        height: 6rem;    
    }

    .card-title {
        font-size: 20px;
    }

    .card-text {
        font-size: 16px;
    }
    .wide_variety {
        flex-direction: column-reverse;
    }
    .lab_exercise_heading {
        font-size: 26px;
        text-align: center;
    }
    .lab_exercise_para {
        font-size: 16px;
        width: 90%;
        margin: 1% 5%;
        text-align: center;
    }
    .lab_exercise_options {
        font-size: 14px;
    }

    .mentor_heading {
        text-align: center;
        font-size: 26px;
        font-weight: 900;
    }
    .mentor_para {
        text-align: center;
        font-size: 16px;
    }
    
    .why_choose_us {
        margin: 25px 10px;
    }
    .why_choose_us_details {
        display: flex;
        flex-direction: column;
    }
    .why_choose_us_header {
        color: black;
        font-size: 12px;
        font-weight: 600;
    }
    
    .why_choose_us_para {
        font-size: 10px;
        width: 90%;
    }
    
    .why_choose_us_img {
        width: 2.5rem;
        height: 2.5rem;
        mix-blend-mode: multiply;
    }
    
    .why_choose_us_img>img {
        border-radius: 50%;
        height: 3rem;
    }

    .vr_different {
        display: flex;
        flex-direction: column-reverse;
    }
    .Gathernutilise_heading {
        font-size: 26px;
        text-align: center;
    }

    .Gathernutilise_para {
        font-size: 16px;
    }

    .Gathernutilise_btn {
        font-size: 14px;
        margin-left: 100px;
    }
  
    
    .ourprocess_heading {
        font-size: 26px;
        width: 100%;
    }
    .accordion-body {
        font-size: 10px;
    }
    
    .Elevate_skills {  
        width: 100%;  
        font-size: 24px;
         }
    .Elevate_skills_para {
        font-size: 16px;
        width: 100%;
        text-align: center;
        }

        .validation_form_heading {
            width: 100%;
            font-size: 26px;
        }
        

    .form_container {
        margin-top: 0px;
        width: 100%;
    }
    
    .safron {
        display: none;
        /* position: relative;
        right: -3px; */
    }
    .label {
        color: #272D3B;
        font-size: 16px;
        font-weight: 600;
    }
    
    .freq_asked {
        font-size: 26px;
        text-align: center;
    }
    
    #vectorHeader {
        display: none;
    }
    .copyrt {
        font-size: 12px;
    }

      /*  */
 
    





  



    .about_image>img {
        width: 350px;
        left: 0;
    }

    .ourprocess_image {
        width: 765px;
        background-color: #F4D3D3;
    }

    .verifies_icon {
        color: #64CCC5;
        margin-right: 5px;
    }


 


    #accordionFlushExample {
        width: 50%;

    }

    #myAccordion {
        justify-content: center;
        align-items: center;
    }

    .about_content {
        margin-left: 100px;
        margin-top: 20px;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
    }




    .item {
        background: white;
        margin-bottom: 5px;
        padding: 10px 20px;
    }

    .tittle {
        color: #111111;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 22px;
        font: bolder;
        cursor: pointer;

    }

    .content {
        color: rgb(165, 161, 161);
        overflow: hidden;
        max-height: 0;
        transition: all 0.5s cubic-bezier(0, 1, 0, 1);
    }

    .contentshow {
        height: auto;
        max-height: 9999px;
        transition: all 0.5s cubic-bezier(0, 1, 0, 1);
    }


    .validation_form {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;

    }



    .blue-button {
        font-size: 26px;
    }

    .field div {
        color: black;
        /* margin: 20px; */
    }

    .ui_form div {
        margin: 10px;
        justify-content: space-between;
    }


    .accordionFlushExample {
        width: 80%;
        margin: 10%;
    }

    .accordionquetions {
        overflow-y: hidden;
    }

    .accordion {
        align-items: center;
    }

   


   


}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 820px) {
    nav {
        width: 100vw;
        display: 'grid';
        position: 'fixed';
        top: '50px';
        padding: 10px;
    }

    .navbar-collapse {
        display: block;
        width: 100vw;
        height: auto;
    }

    .navbar-nav {
        list-style: none;
        display: flex;
        gap: 3rem;
        left: 10px;
        margin-top: 50px;
        margin-left: 80px;
    }

    .nav_items>li {
        color: black;
        font-size: 16px;
        font: bold;
        font-weight: 600;
        text-transform: capitalize;
    }

  


    .why_gnosis_tittle {
        /* font-size: 24px; */
        text-align: center;
    }

    .lab_exercise_heading {
        text-align: center;
    }

    .lab_exercise_para {
        text-align: center;
    }

    .why_choose_us {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 25px 1px;
    }

    .why_choose_us_para {
        width: 100%;
    }

    .why_choose_us_img>img {
        height: 3rem;
    }


    .verifies_icon {
        justify-content: center;
    }

    .about_image {
        margin: 10px auto;
    }

    .mentor_heading {
        text-align: center;
        /* font-size: 36px;
        font-weight: 900;        */
    }

   

    .vr_different {
        display: flex;
        flex-direction: column-reverse;
    }

  

    .Gathernutilise_ul li {
        width: 100%;
        font-size: 20px;
    }

    .Gathernutilise_btn {
        margin-left: 100px;
    }

 

  

  

    #vectorHeader {
        display: none;
    }

}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {

    .verifies_icon {
        justify-content: center;
    }

   

    .Gathernutilise_ul>li {
        width: 100%;
        text-align: center;
    }

    .why_choose_us_img>img {
        height: 3rem;
    }

    #vectorHeader {
        display: none;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {

    nav {
        width: 100vw;
        display: 'grid';
        position: 'fixed';
        top: '50px';
        /* padding: 10px; */

    }

    .navbar-collapse {
        display: block;
        width: 100vw;
        height: auto;
    }

    .navbar-nav {
        list-style: none;
        display: flex;
        gap: 3rem;
        left: 10px;
        margin-top: 50px;
        margin-left: 80px;
    }

    .nav_items>li {
        color: black;
        font-size: 16px;
        font: bold;
        font-weight: 600;
        text-transform: capitalize;
    }




    .why_choose_us {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 25px 1px;
    }

    .why_choose_us_para {
        color: black;
        font-weight: 400;
    }


    .vr_different {
        display: flex;
        flex-direction: column-reverse;
    }

    .Gathernutilise_ul {
        width: 100%;
        font-size: 20px;
    }

  

    #vectorHeader {
        display: none;
    }

 

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {

    .Empower_Enterprice_details {
        margin-left: 100px;
    }

    .ourprocess_image {
        width: 765px;
        height: auto;
    }


}