@import './variable';
.btn-primary {
	background-color: $primary;
	border-color: $primary;
	border-radius: 15px;
	box-shadow: 0px 0px 7px 0px #72727247;
}
.bg-primary {
	background-color: $primary !important;
}
.bg-secondary {
	background-color: $secondary !important;
}
.bg-danger {
	background: $danger !important;
}
.bg-success {
	background: $success !important;
}

.btn-primary:hover {
	color: #fff;
	background-color: $primary;
	border-color: $primary;
}
.btn-primary.focus,
.btn-primary:focus {
	color: #fff;
	background-color: $primary;
	border-color: $primary;
	box-shadow: 0 0 0 0.2rem #2D62ED4d;
}
.btn-primary:hover, .btn-primary:active, .btn-primary:focus{
	background-color: #49167E !important; 
  }
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
	color: #fff;
	background-color: $primary;
	border-color: $primary;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem #2D62ED41;
}
.primary-color {
	background: $primary;
}
.secondary-color {
	background: $secondary;
}
.btn-secondary {
	background-color: $secondary;
	border-color: $secondary;
	border-radius: 1.2rem;
}
.btn-secondary:hover {
	color: #fff;
	background-color: $secondary;
	border-color: $secondary;
}
.btn-secondary.focus,
.btn-secondary:focus {
	color: #fff;
	background-color: $secondary;
	border-color: $secondary;
	box-shadow: 0 0 0 0.2rem #79ecfc4d;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
	color: #fff;
	background-color: $secondary;
	border-color: $secondary;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem #8efbffb2;
}

.primary-color {
	background: $primary;
}
// dropdown

.dropdown-menus {
	display: block;
	// box-shadow: 0px 3px 6px #272d3b33;
	border: 0px;
}

.btn-info {
    color: #fff;
    background-color: #49167E;
    border-color: #49167E;
}
.btn-info:hover {
    color: #fff;
    background-color: #49167E;
    border-color: #49167E;
}
.btn-info.focus, .btn-info:focus {
    color: #fff;
    background-color: #49167E;
    border-color: #49167E;
    box-shadow: 0 0 0 0.2rem rgb(19 22 137 / 16%);
}
.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #0a2d86 !important;
    border-color: #152266 !important;
}
.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(19 22 137 / 16%);
}
// page-items
.page-item.active .page-link {
	z-index: 3;
	color: #ffffff !important;
	font-weight: 600;
	background-color:#49167E !important;
    border-color: #49167E;
}
.page-link:hover {
	z-index: 2;
	color: $fontColor !important;
	text-decoration: none;
	background-color: #e9ecef00 !important;
}
.page-link:focus {
	z-index: 3;
	outline: 0;
	box-shadow: 0 0 0 0 rgb(175, 175, 175) !important;
}
.page-link {
	color: $fontColor;
	min-width: 36px;
    min-width: 32px;
}

//progress

.progress {
	display: flex;
	height: 2rem;
	overflow: hidden;
	line-height: 0;
	font-size: .75rem;
	background-color: #ffffff;
	border-radius: .25rem;
	margin-bottom: 10px;
}
.progress-bar {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	color: #fff;
	text-align: left;
	font-size: 14px;
	padding: 0px 10px;
	justify-content: center;
	white-space: nowrap;
	background-color: $primary;
	transition: width .6s ease;
	border-radius: 20px;
}
.progress-sh {
	height: 20px;
	border-bottom: 0px;
}
.breadcrumb {
	background: transparent;
	background: transparent;
	padding: 0px;
	margin: 0px;
}
.breadcrumb-item + .breadcrumb-item::before {
	display: inline-block;
	padding-right: .5rem;
	color: #6c757d;
	content: "/";
	font-family: monospace;
}
.breadcrumb-item.active {
	color: $fontColor;
	font-weight: bold;
}
.breadcrumb-item a {
	color: $primary;
	font-weight: 600;
  }
.border-danger {
	border-color: #ff637fa3 !important;
}
.alert-primary {
	color: #ffffff;
	background-color: #00ccf2;
	border-color: #d3d3d382;
	box-shadow: 0px 0px 13px 0px #8080805e;
}
.bg-light {
	background: #f5fbff;
}
.spinner-border{
    width: 1.5rem;
    height: 1.5rem;
}
.tab-btn-group .tab-btn:first-child {
	border-radius: 8px 0 0 8px;
  }
  .tab-btn-group .tab-btn:last-child {
	border-radius: 0 8px 8px 0;
	border-right: none;
  }