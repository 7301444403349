.player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  }
  .pdfreader {
    position: relative;
    padding-top: none /* Player ratio: 100 / (1280 / 720) */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    /* padding:5px ; */
    /* box-shadow:1px 1px 10px 1px; */
  }
  .react-player__shadow{
    background: #42307d !important;
  }
  