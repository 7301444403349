@import './variable';
// typography
.label {
	color: $fontColor;
	font-size: 13px;
	font-weight: 600;
}
.tnc-label {
	font-size: 13px;
  }
  .tnc-label .custom-control-label {
	line-height: 22px;
	color: #555;
  }
.title-lg {
	font-size: 18px;
	color: $titleColor;
	font-weight: 600;
	margin-bottom: 10px;
	line-height: 30px;
}
.title-md {
	font-size: 16px;
	font-weight: 600;
	color: $titleColor;
	margin-bottom: 10px;
	line-height: 22px;
}

.title-sm {
	font-size: 13px;
	font-weight: 600;
	color: $fontColor;
}
.cat-title-md {
	font-weight: 600;
	font-size: 16px;
	color: #111;
	margin-bottom: 3px;
  }
  .cat-title-sm {
	font-size: 12px;
	line-height: 18px;
	color: #555;
  }
  .table-bless{
	font-size: 12px;
  }
.full-w {
	width: 100%;
}
.full-h {
	height: 100%;
}

.flx1 {
	flex: 1;
}
.flx3 {
	flex: 3;
}
.flx4 {
	flex: 4;
}
.flx5 {
	flex: 5;
}
.column {
	display: flex;
	flex-direction: column;
}
.jcb {
	display: flex;
	justify-content: space-between;
}
.jcb-c {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.jcc-c {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.jcc {
	display: flex;
	justify-content: center;
}
.vic {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.aic {
	display: flex;
	align-items: center;
}
.jce {
	display: flex;
	justify-content: flex-end;
}
.jcse {
	display: flex;
	justify-content: space-evenly;
}
.flx {
	display: flex;
}
.elps {
	white-space: nowrap;
	// overflow: hidden;
	text-overflow: ellipsis;
}
.table-bless .table td,
.table th {
	padding: 5px 0px;
}
.table-bless thead td {
	padding-bottom: 10px !important;
}
.round{
	border-radius: 30px;
}
.bold-ft{
	font-weight: 600;
}
.checkbox-div {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	border: 1px solid rgba(0, 0, 0, 0.308);
	cursor: pointer;
	margin: 0px 5px;
}
.f13{
	font-size: 13px;
}
.tab-btn {
	width: 100%;
	background: #ececec;
	color: #333;
	padding: 7px 10px;
	border-radius: 2px;
	cursor: pointer;
	text-align: center;
	font-size: 13px;
   border-right: 1px solid #ddd;
}
.disable{
	opacity: 0.5;
    pointer-events: none;
}
.tab-btn.bg-primary {
	color: #fff;
  }
.success-checkbox .custom-input input:checked + label:before {
	background-color: $success;
	border: 1px solid $success;
}
.tw {
	color: white;
}
.tb {
	color: rgb(5, 5, 5);
}
.f12 {
	font-size: 12px;
}
.f13 {
	font-size: 13px;
  }
.op5 {
	opacity: 0.5;
}

@mixin gnBatch {
	color: white;
	border-radius: 5px;
	font-size: 14px;
	padding: 1px 15px;
	text-align: center;
	align-items: center;
	margin: 0px 10px;
	cursor: pointer;
}
.batch-pri {
	@include gnBatch;
	background-color: $primary !important;
}
.batch-sec {
	@include gnBatch;
	background-color: $secondary !important;
}
.btn-active {
	opacity: 1;
}
.link {
	color: #2d62ed;
	cursor: pointer;
}
.d-grid {
	display: grid;
}
