.maincertificate{
  background-color: #ffffff;
  font-family: "Open Sans", sens-serif;
  text-align: center;
}



  
  .popup {
    border: 0px solid #ffffff;
    width: 350px;
    height: 500px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    box-shadow: 0px 3px 6px 2px rgba(144, 144, 242, 1.5);
  }
  
  .first-block {
  
    border: 0px solid #ffffff;
    width: 350;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px 5px 0px 0px;
  }
  .certificate-icon{
    width:250px
  }
  
  
  
  .close:hover {
    opacity: 1;
  }
  
  .close::before, .close::after {
    position: absolute;
    left: 15px;
    content: "";
    height: 32px;
    width: 2px;
    background-color: #333;
    display: none;
 
  }
  
  .close::before {
    transform: rotate(45deg);
   
  }
  
  .close::after {
    transform: rotate(-45deg);
    
  }
  /*End of X Close Button */
  
 
  
  .congr {
    color: #687C99;
    font-size: 24px;
    margin-top: 30px;
  }
  
  .congr + p {
    color: #95A3B0;
  }
  
  button[name="share"] {
    color: #001867;
    border: 0px solid #ffffff;
    width: 170px;
    border-radius: 20px;
    font-size: 16px;
    height: auto;
    padding: 10px;
    box-shadow: 0px 7px 35px 0px  rgba(167, 186, 244, 0.7);
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
  }
  
  button[name="share"]:hover {
    background-color: #1E52F7;
    color: #ffffff;
  }