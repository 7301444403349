@import './variable', './bsCss', './utils';

@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"), url("../Fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
	font-weight: 400;
}
@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"), url("../Fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
	font-weight: 700;
  }
  @font-face {
	font-family: "Montserrat";
	src: local("Montserrat"), url("../Fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
	font-weight: 600;
  }
  .btn-am{
	background-color: #49167E;
	border: none;
	padding: 12px 10px !important;
	border-radius: 25px;
	font-size: 16px;
	font-weight: 600;
  }
  .btn-am:hover, .btn-am:active, .btn-am:focus{
	background-color: #49167E !important; 
  }
body {
	font-size: 14px !important;
	font-family: 'Montserrat', sans-serif !important;
}
p {
	margin-top: 0;
	margin-bottom: 0px;
	color: $fontColor;
}
.main-page {
	display: flex;
	height: 100%;
	width: 100%;
}
.dashboard-page {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	background-color: #f9f9f9;
}
.box-shadow {
	width: 100%;
	// height: 100%;
	min-height: 180px;
	// box-shadow: $boxShadow;
	border-radius: 20px;
	padding: 14px 20px;
	border: 1px solid #eee;
  	background-color: #fff;
}
.table-shadow {
	box-shadow: $boxShadow;
	border-radius: 20px;
	overflow: hidden;
	width: 100%;
	display: flex;
	flex-direction: column;
	border: 1px solid #eee;
  background-color: #fff;
}
.card-action-icon {
	margin-top: -12px;
	margin-right: -12px;
  }
.dashboard-container {
	width: 100%;
	padding: 10px 25px 25px 25px;
}

.check-box {
	width: 25px;
	height: 25px;
	box-shadow: 0px 3px 6px #00000029;
	background: #ffffff;
	padding: 15px;
	margin-bottom: 10px;
	color:#111;
	border: 1px solid #ddd;
}
.square-btn {
	padding: 3px 5px;
	text-align: center;
	border-radius: 3px;
	cursor: pointer;
	background-color: #00ccf2;
	box-shadow: 0px 2px 3px #000000a6;
	color: white;
	position: relative;
	font-weight: 500;
}

.circle-md {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background: #49167E !important;
	color: white;
	cursor: pointer;
}
.red-circle {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #ff007c;
	margin-right: 10px;
}

.text-sm {
	font-size: 13px;
	opacity: 0.5;
	color: $fontColor;
}
.btn-round {
	width: 40px;
	height: 40px;
	align-items: center;
	display: grid;
	justify-content: center;
}

.bg-purple {
	background: #7d00b5 !important;
}
.pointer {
	cursor: pointer;
}
.notification .dropdown-menu {
	display: block;
	box-shadow: 0px 2px 6px #272d3b33;
	border: 0px;
	padding: 20px 40px;
	border-radius: 20px;
	width: 350px;
	margin-top: 15px !important;
}
.modal-backdrop.show {
	opacity: .6;
}
.modal-backdrop {
	position: fixed !important;
	top: 0 !important;
	left: 0 !important;
	z-index: 1040 !important;
	width: 100vw !important;
	height: 100vh !important;
	background-color: #d6d6d6a8 !important;
}
.modal-content {
	position: relative !important;
	display: flex !important;
	flex-direction: column !important;
	width: 100% !important;
	pointer-events: auto !important;
	background-color: #fff !important;
	background-clip: padding-box !important;
	box-shadow: 0px 0px 6px 0px #5555554a !important;
	border-radius: 20px !important;
	outline: 0 !important;
	border: 0px !important; 
}
.chat-container {
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 10px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: auto;
}
.chat-body {
	width: 100%;
	display: flex;
	height: 100%;
	justify-content: flex-end;
	overflow-y: auto;
	flex-direction: column;
}
.chat-send {
	box-shadow: 0px 0px 1px 0px #0000002b;
	border-radius: 20px;
	width: 100%;
	padding: 0px 10px;
	background: #f5fbff;
	height: 39px;
	margin: 15px;
	margin-bottom: 10px;
	overflow: hidden;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.chat-send input {
	width: 100%;
	border: 0px;
}
.chat-send .form-control {
	background-color: transparent;
}
.chat-send input:focus {
	box-shadow: 0px 0px 0px 0px black;
	border: 0px;
}
.primary-cir {
	width: 32px;
	height: 32px;
	background-color: #00ccf2;
	border-radius: 50%;
	display: grid;
	text-align: center;
	font-size: 11px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.inbound-chat {
	width: 100%;
	padding: 10px;
	display: flex;
	position: relative;
}
.chat-bubble {
	width: 269px;
	border: 1px solid;
	border-radius: 14px;
	font-size: 12px;
	padding: 10px;
}
.inbound-chat .chat-bubble {
	border-top-left-radius: 0px;
	background: $secondary;
	color: white;
}
.outbound-chat .chat-bubble {
	border-top-right-radius: 0px;
	background: #e7e7e7;
	color: black;
	border: 0px;
}
.outbound-chat {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	padding: 0px 10px;
}

.user-img {
	width: 35px;
	height: 35px;
	border: 1px solid;
	border-radius: 50%;
	display: grid;
	align-items: center;
	justify-content: center;
	margin-right: 4px;
}
.btn-square {
	width: 40px;
	height: 40px;
	padding: 10px;
	display: grid;
	background: #4a72cf;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	cursor: pointer;
	color: white;
	opacity: 0.5;
}

.activity-card {
	width: 100%;
	height: 130px;
	border-radius: 10px;
	padding: 15px;
	border: 1px solid #ddd;
	margin-bottom: 10px;
	color: black ;
}
.table-footer-action {
	padding: 0px 10px;
	display: flex;
	justify-content: flex-end;
	margin: 0px 0;
}
#zmmtg-root {
	display: none !important;
  }
  .custom-file-upload {
    display: flex;
    justify-content: space-between;
    padding: 0px 8px;
    cursor: pointer;
    font-size: 14px;
    width: 100%;
    text-align: right;
    margin-bottom: 0px;
}