.css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type) {
    margin-left: -1px;
    border-left: 1px solid transparent;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: 700;
}
.css-1ynyhby {
    margin: 8px;
   
    background: #FBF2D5;
    border-radius: 10px;
 box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);;
}
.css-18k87ye-MuiTypography-root {
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    margin-bottom: 0.35em;
    padding: 10px;
}

.css-1howxi1-MuiTableCell-root {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 600 !important;
    /* font-size: 0.875rem; */
    line-height: 1.5rem;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: center !important;
    padding: 6px 16px;
    font-size: 13px !important;
    color: #49167E !important;
}
.css-6qfsqn-MuiTableCell-root {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 600 !important;
    /* font-size: 0.875rem; */
    line-height: 1.5rem;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: right;
    padding: 6px 16px;
    font-size: 13px !important;
    color: #49167E !important;

}

.css-9l5vo-MuiCollapse-wrapperInner {
    width: 100%;
    height: 340px !important;
    overflow-x: scroll !important;
    overflow-y: scroll !important;
}

.css-2blr9s-MuiTableRow-root {
    color: inherit;
    display: table-row;
    vertical-align: middle;
    outline: 0;
    background: #c1cdd982;
}
.css-dsuxgy-MuiTableCell-root{
    text-align: center !important;
}