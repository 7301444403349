.loginScreen {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
        
          /* background-color: #F5F8FB; */

         
}
/* .loginbackground{
 background: url("../../../Assets/Images/Mediamodifier-Design.svg") ; 

      
   

          background-repeat: no-repeat;
          background-position: center center;
          background-attachment: fixed;
          background-size: 100%;
          height: 100vh;
} */
.loginLeftScreen {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
  text-align: justify;
  padding: 0px 30px !important;
  background-image: url("../../../Assets/Images/screen.jpg");
  position: relative;
}

.loginLeftScreen:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#22ccff), to(#1177b2));
  background: linear-gradient(to bottom, #22ccff, #1177b2);
  opacity: .8;
}

.loginLeftScreen h1 {
  font-size: 30px;
  margin-bottom: 15px;
}

.loginRightScreen {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.login-container {
  width: 450px;

  margin-left: -38px;
}

.login-form {
  background: white;
  padding: 0px 20px 20px 20px;
  -webkit-box-shadow: 0px 0px 15px 0px #00000021;
          box-shadow: 0px 0px 15px 0px #00000021;
  border-radius: 20px;
}


.pwd-container {
  position: relative;
  /* width: 295px; */
}

/* .pwd-container .input {
  padding: 5px 30px 5px 10px;
  font-size: 20px;
} */

.login-navigation{
  
    align-items: center;
    background: #0c111d;
    background: var(--Gray-950, #0c111d);
    border-bottom: 1px solid #475467;
    border-bottom: 1px solid var(--Gray-600, #475467);
    display: flex;
    height: 72px;
    height: var(--nav-height);
    justify-content: space-between;
    position: relative;

}
.login-header{

    color: #101828;
    color: var(--Gray-900, #101828);
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
    font-family: "Inter";

}
.login-text{
  color: #475467;
  color: var(--Gray-600, #475467);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.pwd-container img {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 8px;
  top: 40px;
}
.disablediv{
  pointer-events: none;
  opacity: 0.5;
}
