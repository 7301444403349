.product {
    
    padding: 5px;
   
   /* margin-top: -5px; */
    
    cursor: pointer;
    margin-top: 5px;
}

.product-text {
    /* float: left;
    width: auto;
    display: flex;
    font-size:14px; */
    color:  #101828;
/* Text lg/Semibold */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px;
   
}
/* .product:hover .product-text {
    display: flex;
} */
.modalprogress{
    position: absolute;
    background: #ffffff;
    left: 25%;
    top: 10%;
    padding: 0;
}