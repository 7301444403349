.user-profile-container{
    width: 100%;
    padding: 14px;
    width: 100%;
    background-color: #F2F2F2;
    text-align: center;
    border-radius: 20px;
}
.labbacimg{
  background-image: url("../../../Assets/Images/labimage-transformed.jpeg");
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center  ;
 
}
.assesmentimg{
  background-image: url("../../../Assets/Images/assesment.jpg");
 height: 420px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.zoommeeting{
  background-image: url("../../../Assets/Images/joinnow.jpeg");
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: 1% 38%  ;
  background-size: 100%;
}
.user-pf{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    align-items: center;
    border: 1px solid #ddd;
    justify-content: center;
    display: grid;
}
.left-details {
    border: 1px solid #ddd;
    border-radius: 15px;
    margin-right: 15px;
    padding: 15px 15px;
    max-height: 550px;
}
.user-activity{
    display: flex;
    padding: 10px;
    justify-content: space-between;
    border-radius: 15px;
    /* box-shadow: 0px 3px 6px #00000029; */
    margin-bottom: 20px;
    flex-direction: column;
    max-width: 192px;
    flex: 1;
    margin: 0 5px;
    text-align: center;
    border: 1px solid #ddd;

}
.activities-btn{
    width: 55px;
    height: 60px;
    background: #2D62ED;
    border-radius: 6px;
    display: grid;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px #272d3b33;
}
.training-progress{
    width: 120px;
    text-align: center;
}

/* tabs */

/*
 CSS for the main interaction
*/
.training-content{
  height: 100vh; 
  margin-left: -25px;  
  overflow: hidden;
   background: "#F7F9FA";
   position: relative;
   margin-top: -20px;
}
.training-content:hover{
  overflow: auto;
  width: calc(100% - 17px);
  /* height: 100%; */
  padding-right:10px ;
}
.training-content::-webkit-scrollbar{
  width: 5px;
}



.tabset > input[type="radio"] {
    position: absolute;
    left: -200vw;
  }
  
  .tabset .tab-panel {
    display: none;
  }
  
  .tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
  .tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
  .tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
  .tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
  .tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
  .tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
    display: block;
  }
  
  
  
  .tabset > label {
    position: relative;
    display: inline-block;
    padding: 12px 15px 9px;
    /* border: 1px solid transparent; */
    /* border-bottom: 0; */
    cursor: pointer;
    font-weight: 600;
    /* color: #6941c6; */
    font-family: 'inter';
  }
  
  .tabset > label::after {
    content: "";
    position: absolute;
    left: 15px;
    bottom: 5px;
    /* width: 22px; */
    height: 1px;
    color: #6941C6;
    background: #f9f5ff;
    border-bottom:  2px solid #6941C6;
    
  }
  
  .tabset > label:hover,
  .tabset > input:focus + label {
    color: #6941C6;
  }
  
  .tabset > label:hover::after,
  .tabset > input:focus + label::after,
  .tabset > input:checked + label::after {
    background: #6941C6;
  }
  
  .tabset > input:checked + label {
    
    border-bottom:  2px solid #6941C6;
   color:#6941C6
  }
  
  
  .tab-panels {
    padding: 5px 0 20px;
    border-top: 1px solid #ccc;
  }
  
 
  
  /* .tabset {
    max-width: 65em;
  } */

  .modal {
    background: rgba(0, 0, 0, 0.6) !important;
  }
  .modal-container {
    position: absolute !important;
   
    background: #ffffff !important;
    left: 10% !important;
    top: 12% !important;
    padding: 2px 5px 5px 0px !important;
    width: 80% !important;
    border-radius: 15px !important;
    
  }
  iframe{
    margin: 0;
    padding: 0;
  }
  viewer-toolbar{
    display: none !important;
  }

  /* css for error component */
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: .25rem;
  }
  
  .card .card-header {
    background-color: #fff;
    border-bottom: none;
  }
  input[type=checkbox][disabled]{
    outline:1px solid #3f51b5; 

  }
  

  .dropbtn {
 
    color: #3f51b5;
    /* padding: 16px; */
    /* font-size: 16px; */
    border: none;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid ;
    padding: 0px 5px;
    border-radius: 5px;
  }
  
  .dropdown-resources {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content-resources {
    display: none;
    position: absolute;
    background-color: #f9f9f9;

    z-index: 1;
    padding: 15px ;
    width: 100%;
    
  }
/*   
  .dropdown-content-resources a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1}
   */
  .dropdown-resources:hover .dropdown-content-resources {
    display: block;
  }
  
  /* .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  } */
  .disabled-button {
    cursor: not-allowed;
    opacity: 0.6;
  }

/* After Design  */
.training-title{
  color:  #101828;

/* Display sm/Semibold */
font-family: Inter;
font-size: 30px;
font-weight: 600;
font-style: normal;
line-height: 38px;
display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
 
}
.showcontentdiv{
  border-radius: 8px;
  background:  #F4EBFF;
  height: 70vh;
  margin-top: 10px;
  
  /* Shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}

.showcontentconsumer{
 
  height: 70vh;
  margin-top: 10px;
  
  border-radius: 8px;
  background: var(--Green-50, #EDFCF2);
  
  /* Shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);

  
}

.showcontentdiv-fullscreen{
  border-radius: 8px;
  background:  #F4EBFF;
  height: 100vh;
  width: 100vw;
  margin-top: 10px;
  
  /* Shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}
.labassessmentcloundcontent{
  border-radius: 8px;
  background:  #F4EBFF;
 min-height: 70vh;
 
  margin-top: 10px;
  
  /* Shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}
.newass{
  background: #f9fafb;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-align: center;
    align-items: center;
}



.twobtn{
  display: flex;
padding: 10px 18px;
justify-content: center;
align-items: center;
gap: 8px;
}

.normalbtn{
  border-radius: 8px;
border: 1px solid  #D0D5DD;
background: #FFF;
padding: 5px 10px;
justify-content: center;
align-items: center;
/* Shadow/xs */
font-family: Inter;
font-size: 16px;
font-style: normal;
/* font-weight: 600;
line-height: 24px; */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.downloadbutton{
  border-radius: 8px;
  color: #fff;
border: 1px solid  #7F56D9;
background:  #7F56D9;
padding: 10px 16px;
font-family: Inter;
font-size: 16px;
font-style: normal;
/* font-weight: 600; */
line-height: 24px;
/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
width: 100%;
}
.overallprogress{
  /* display: flex; */
  margin-top: 10px;
padding: 16px 20px;
/* justify-content: space-between;
align-items: center;
align-self: stretch; */
border-radius: 8px;
border: 1px solid  #EAECF0;
background: #FFF;
/* Shadow/sm */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);


}
.trainingnavigator{
  /* display: flex; */
/* padding-left: 24px; */
justify-content: space-between;
align-items: center;
align-self: stretch;
border-bottom: 1px solid  #EAECF0;
background:  #FFF;
border-radius: 8px;
border: 1px solid  #EAECF0;
margin-top: 10px;
/* padding: 10px 10px; */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
/* height: 100vh; */
overflow-y: hidden;

}
/* .trainingnavigator:hover{
  overflow-y: scroll;
  scrollbar-width: thin;
}
.trainingnavigator::-webkit-scrollbar {
  width: 2px;

} */
.trainingnavigatortext{
  color:  #101828;

/* Text lg/Semibold */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
/* padding: 16px; */
}

.contentandresourcestitle{
  color:  #101828;

/* Text md/Medium */
font-family: 'Inter';
font-size: 16px;
font-style: normal;
font-weight: 500;

}

.contentandresources-subtitle{
  color:  #475467;

/* Text md/Regular */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 400;

}
.trainingoverview{
  color:  #475467;

  /* Text md/Regular */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.training-session-parrent{
  border-radius: 12px;
background: var(--Base-White, #FFF);
display: flex;
margin: auto;
flex-direction: column;
/* align-items: center; */
flex-shrink: 0;

padding: 24px 24px 0px 24px;

align-items: flex-start;
gap: 16px;
align-self: stretch;
}
.training-session-header{
  color: var(--Gray-900, #101828);

/* Text lg/Semibold */
font-family: "Inter";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
}

.training-session-newsub-header{
  color: var(--Gray-600, #475467);

/* Text sm/Regular */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
}



/* assmessment */


.assessment-session-parrent{
  display: flex;
padding: 24px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
margin: 50px 80px;
border-radius: 12px;
background: var(--Base-White, #FFF);


/* Shadow/sm */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}

.assessment-session-header{
  color: var(--Gray-900, #101828);

/* Text lg/Semibold */
font-family: "Inter";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.assessment-session-card{
  border-radius: 12px;
border: 1px solid var(--Gray-200, #EAECF0);
background: var(--Base-White, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
display: flex;
padding: 16px 24px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
}

.assessment-session-card-title{
  color: var(--Gray-600, #475467);

/* Text sm/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
}
.assessment-session-card-score{
  color: var(--Gray-900, #101828);

/* Display md/Semibold */
font-family: Inter;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 44px; /* 122.222% */
letter-spacing: -0.72px;
}


  .assessment-task-deatls{
 
      /* display: flex; */
   
      padding: 16px 24px 16px 0px;
      align-items: center;
      /* gap: 12px; */
      align-self: stretch;
    

  }
  .assessment-task-parrent{
  height:180px ;
  overflow-y:scroll ;
  width: 100%
  }
  
  .coding-assessment-task-parrent{
    margin-top: 20px;
    height:200px ;
    overflow-y:scroll ;
    width: 100%
  }
  .assessment-task-parrent::-webkit-scrollbar {
    width: 8px;
  }

  .coding-assessment-task-parrent::-webkit-scrollbar {
    width: 8px;
  }
  .coding-assessment-task-parrent::-webkit-scrollbar-thumb {
   
    border-radius: 8px;
background: var(--Gray-200, #EAECF0);
  }

   
  /* .assessment-task-parrent::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  } */
   
  .assessment-task-parrent::-webkit-scrollbar-thumb {
   
    border-radius: 8px;
background: var(--Gray-200, #EAECF0);
  }



  /* coding asss */
  .coding-assessment-session-parrent{
    display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  margin: 30px 30px;
  border-radius: 12px;
  background: var(--Base-White, #FFF);
  
  
  /* Shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  }
  .btn_addcalendar {
    background: #fff;
    background: var(--Base-White, #fff);
    border: 1px solid #d0d5dd;
    border: 1px solid var(--Gray-300, #d0d5dd);
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 #1018280d;
    color: #344054;
    color: var(--Gray-700, #344054);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding: 12px;
    text-align: center;
}
 
@media print {
  .no-print {
    display: none;
  }
}





