.grid-batch1 {
    width: 280px;
    height: 112px;
    border-radius: 20px;
    background-color: #C8A3DF;
    padding: 15px;
    color: #000000;
    display: flex;
    font-size: 20px;
    /* flex-direction: column; */
    justify-content: space-between;
}
.grid-batch2 {
    width: 280px;
    height: 112px;
    border-radius: 20px;
    background-color: #BFCBF7;
    padding: 15px;
    color: #000000;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    font-size: 20px;
}
.grid-batch3{
    
    width: 280px;
    height: 112px;
    border-radius: 20px;
    background-color: #D0EFFA;
    padding: 15px;
    color: #000000;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    font-size: 20px;
}

/* .card {
    border: 1px solid;
    border-radius: 10px
} */

.days span {
    font-weight: 300;
    font-size: 13px
}



.badge span {
    background-color: #fffbec;
    width: 60px;
    height: 25px;
    padding-bottom: 3px;
    border-radius: 5px;
    display: flex;
    color: #fed85d;
    justify-content: center;
    align-items: center
}

/* .progress {
    height: 10px;
    border-radius: 10px
}

.progress div {
    background-color: red
} */

.c-details{
    color:#9878d5;
    font-size: 15px;
}
.c-details{
  
    font-size: 15px;
    font-weight: 600;
}

.text1 {
    font-size: 14px;
    font-weight: 600
}

.text2 {
    color: #a5aec0
}




/* strenth */
.my-card
{
    position:absolute;
    left:40%;
    top:-20px;
    border-radius:50%;
}
.bor{
    border-color: #7214AE;
    opacity: 0.9;
border-radius: 15px;
/* transform: matrix(1, 0, 0, -1, 0, 0); */
/* border-top-color: rgb(227, 208, 239); */
}

/* leaderboardTimeline */


/* .card {
   box-shadow: 0 0.46875rem 2.1875rem rgba(4,9,20,0.03), 0 0.9375rem 1.40625rem rgba(4,9,20,0.03), 0 0.25rem 0.53125rem rgba(4,9,20,0.05), 0 0.125rem 0.1875rem rgba(4,9,20,0.03);
   border-width: 0;
   transition: all .2s;
} */

/* .card {
   position: relative;
   display: flex;
   flex-direction: column;
   min-width: 0;
   word-wrap: break-word;
   background-color: #fff;
   background-clip: border-box;
   border: 1px solid rgba(26,54,126,0.125);
   border-radius: .25rem;
}

.card-body {
   flex: 1 1 auto;
   padding: 1.25rem;
} */
.vertical-timeline {
   width: 100%;
   position: relative;
   padding: 1.5rem 0 1rem;
}

.vertical-timeline::before {
   content: '';
   position: absolute;
   top: 0;
   left: 67px;
   height: 100%;
   width: 4px;
   background: #e9ecef;
   border-radius: .25rem;
}

.vertical-timeline-element {
   position: relative;
   margin: 0 0 1rem;
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
   visibility: visible;
   animation: cd-bounce-1 .8s;
  
}
.vertical-timeline-element-icon {
   position: absolute;
   top: 0;
   left: 60px;
}

.vertical-timeline-element-icon .badge-dot-xl {
   box-shadow: 0 0 0 5px #fff;
}

.badge-dot-xl {
   width: 18px;
   height: 18px;
   position: relative;
}
.badge:empty {
   display: none;
}


.badge-dot-xl::before {
   content: '';
   width: 10px;
   height: 10px;
   border-radius: .25rem;
   position: absolute;
   left: 50%;
   top: 50%;
   margin: -5px 0 0 -5px;
   background: #fff;
}

.vertical-timeline-element-content {
   position: relative;
   margin-left: 90px;
   font-size: .8rem;
}

.vertical-timeline-element-content .timeline-title {
   font-size: .8rem;
   text-transform: uppercase;
   margin: 0 0 .5rem;
   padding: 2px 0 0;
   font-weight: bold;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
   display: block;
   position: absolute;
   left: -90px;
   top: 0;
   padding-right: 10px;
   text-align: right;
   color: #adb5bd;
   font-size: .7619rem;
   white-space: nowrap;
}

.vertical-timeline-element-content:after {
   content: "";
   display: table;
   clear: both;
}
/* assesment */

.assementdashboard{
    margin-top: 180px;
    margin-left: -90px !important;
}
/* upcoming */


.single-blog-list-inner .details .blog-meta {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    line-height: 1;
  }
  .single-blog-list-inner .details .blog-meta li {
    display: inline-block;
    margin-right: 12px;
    font-size: 12px;
    color: var(--heading-color);
  }
  .single-blog-list-inner .details .blog-meta li i {
    margin-right: 4px;
  }
  .single-blog-list-inner .details .blog-meta li:last-child {
    margin-right: 0;
  }

  .Strength-modal-container {
   position: absolute;
   background: #D0EFFA;
   left: 25%;
   top: 20%;
   padding: 20px;
   right: 25%;
   border-radius: 20px;
   border-left: 2px solid #C8A3DF;
}

.continue {
   background: #c4b1d9;
   border: 1px solid #ddd;
   border-radius: 15px;
   cursor: pointer;
   display: inline-block;
   font-size: 13px;
   margin: 0 18px 5px;
   padding: 6px;
}