.oneline {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .labname-filter {
    border-radius: 12px;
    border: 1px solid var(--Gray-200, #EAECF0);
    background: var(--Base-White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  
}
.labnamefilter-body {
    display: flex;
    /* padding-top: 16px; */
    flex-direction: column;
    gap: 20px;
    align-self: stretch;
}
.labname-in {
    color: var(--Gray-700, #344054);
  
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* border-bottom: 1px solid; */
    border-bottom: 1px solid var(--Gray-200, #EAECF0);
    padding: 20px 24px;
    cursor: pointer;
}
.labname-in.selected {
    border-bottom: 2px solid #6941c6;
    color: #6941c6;
    background-color: #f9f5ff;
  }
  .labname-in:hover {
    background-color: #f0f0f0;
  }
 
  
  .labname-in.selected:hover {
    color: #6941c6;
    background-color: #f9f5ff;
  }

  .create-lab-user-btn {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--Primary-600, #7f56d9);
    background: var(--Primary-600, #7f56d9);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--Base-White, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.lab-user-archive {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #d0d5dd);
    background: var(--Base-White, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--Gray-700, #344054);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.no-lab-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #555;
    font-size: 1.2rem;
    text-align: center;
  }

  /* for react-pdf */
.document-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.react-pdf__Document {
  width: 100%;
}

.react-pdf__Page {
  width: 100%;
  display: flex;
  justify-content: center;
}

.page {
  width: 100%;
}
